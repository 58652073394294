export const convertRequestStatusToString = (status: string): string => {
  switch (status) {
    case 'PENDING':
      return 'Pendiente';

    case 'SCHEDULED':
      return 'Planificada';

    case 'PROCCESSED':
      return 'Tratada';

    case 'RETIRED':
      return 'Retirada';

    default:
      return 'ERROR - Contacte al adminsitrador';
  }
};

export const convertRequestCategorytoString = (category: string): string => {
  switch (category) {
    case 'optimist-timoneles':
      return 'Optimist Timoneles';

    case 'optimist-principiantes':
      return 'Optimist Principiantes';

    default:
      return category;
  }
};

export const generateAudienceParticipantString = (participant: {
  category: string;
  sailNumber: string;
}): string => {
  return convertRequestCategorytoString(participant.category) + ' - ' + participant.sailNumber;
};
