import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Divider from '@mui/material/Divider';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DocumentIcon from '@mui/icons-material/Article';
import EditIcon from '@mui/icons-material/Edit';
import {
  convertRequestStatusToString,
  generateAudienceParticipantString,
} from '../../../utils/audienceUtils';
import { useState } from 'react';
import AudienceStatusUpdateOrganism from './AudienceStatusUpdateOrganism';
import { Audience } from '../../../entities/requests/audience.entity';

import { useSelector, useDispatch } from 'react-redux';
import {
  selectAllFormData,
  resetValuesToInitState,
  changeFormValue,
} from '../../../redux/features/requests/audiences/audiencesSlice';
import { Stack } from '@mui/material';

export default function AudienceAccordionOrganism({
  isAdmin,
  audience,
}: {
  isAdmin?: boolean;
  audience: Audience;
}) {
  const statusUpdateFormData = useSelector(selectAllFormData);
  const dispatch = useDispatch();

  const [status, setStatus] = useState<string>(
    audience.status !== undefined ? audience.status[0].status : '',
  );
  const [updateStatusDialogIsOpen, setUpdateStatusDialogIsOpen] = useState<boolean>(false);
  const [changeStatusFormValues, setChangeStatusFormValues] = useState<{
    status: string;
    date: Date | null;
  }>({
    status: '',
    date: null,
  });

  const handlers = {
    handleAudienceStatusChange: (_id: string) => {
      if (statusUpdateFormData._id !== _id) {
        dispatch(resetValuesToInitState());
        dispatch(
          changeFormValue({
            key: '_id',
            value: _id,
          }),
        );
      }
      setUpdateStatusDialogIsOpen(true);
    },
  };

  let commentary: string | undefined;
  let shortResolution: string | undefined;

  if (
    audience.status !== undefined &&
    (audience.status[0].status === 'PENDING' || audience.status[0].status === 'SCHEDULED')
  ) {
    commentary = audience.status[0].comment;
    shortResolution = undefined;
  } else if (audience.status !== undefined) {
    shortResolution = audience.status[0].resolution?.shortResolution;
    commentary = undefined;
  }

  return (
    <>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls='panel1a-content'
          id='panel1a-header'
        >
          <div className='w-full flex'>
            <Typography className='basis-1/2' style={{ fontWeight: 'bold' }}>
              Audiencia {audience.audienceId}
            </Typography>
            {audience.status !== undefined && audience.status[0].status !== 'PENDING' && (
              <Typography className='basis-1/2'>
                {commentary ? commentary : shortResolution}
              </Typography>
            )}
            {audience.status !== undefined && audience.status[0].status === 'PENDING' && (
              <Typography className='basis-1/2'>
                {audience.status !== undefined
                  ? convertRequestStatusToString(audience.status[0].status)
                  : convertRequestStatusToString('')}
              </Typography>
            )}
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <div className='flex flex-col sm:hidden' style={{ marginTop: '-25px' }}>
            <Stack direction='row' alignItems='left' spacing={1}>
              <IconButton
                color='primary'
                aria-label='upload picture'
                component='label'
                onClick={() => {
                  window.location.href = '/solicitudes/audiencia/' + audience._id;
                }}
              >
                <DocumentIcon fontSize='large' />
              </IconButton>
              {isAdmin && (
                <IconButton
                  color='primary'
                  aria-label='upload picture'
                  component='label'
                  onClick={() => {
                    if (audience._id) handlers.handleAudienceStatusChange(audience._id);
                  }}
                >
                  <EditIcon fontSize='large' />
                </IconButton>
              )}
            </Stack>
          </div>
          <div className='flex flex-row'>
            <div className='flex flex-col sm:basis-5/6'>
              <div className='flex flex-row'>
                <div className='flex flex-col basis-1/2 p-2'>
                  <h1 className='font-bold'>Solicitante:</h1>
                  <p>{generateAudienceParticipantString(audience.requester)}</p>
                </div>
                <div className='flex flex-col basis-1/2 p-2'>
                  <h1 className='font-bold'>Protestados: </h1>
                  {audience.participants.map((participant, index: number) => {
                    return <p key={index}>{generateAudienceParticipantString(participant)}</p>;
                  })}
                </div>
              </div>
              <div className='flex flex-row'>
                <div className='flex flex-col basis-1/2 p-2'>
                  <h1 className='font-bold'>Testigos:</h1>
                  {audience.incident.witnesses.length === 0 && <h1>---</h1>}
                  {audience.incident.witnesses.length !== 0 &&
                    audience.incident.witnesses.map((witnes, index) => {
                      return <p key={index}>{generateAudienceParticipantString(witnes)}</p>;
                    })}
                </div>
              </div>
              <div className='flex flex-row mt-6'>
                <div className='flex flex-col basis-1/4 p-2'>
                  <h1 className='font-bold'>Estado: </h1>
                  <h1 className='font-bold'>{commentary ? 'Comentario:' : 'Resolucion Corta:'}</h1>
                </div>
                <div className='flex flex-col basis-3/4 p-2'>
                  <h1>
                    {audience.status !== undefined
                      ? convertRequestStatusToString(audience.status[0].status)
                      : convertRequestStatusToString('')}
                  </h1>
                  <div className='flex flex-col justify-end w-full h-full'>
                    <h1>{commentary ? commentary : shortResolution}</h1>
                  </div>
                </div>
              </div>
            </div>
            <div className='sm:flex flex-col hidden sm:basis-1/6'>
              <IconButton
                color='primary'
                aria-label='upload picture'
                component='label'
                onClick={() => {
                  window.location.href = '/solicitudes/audiencia/' + audience._id;
                }}
              >
                <DocumentIcon fontSize='large' />
              </IconButton>
              {isAdmin && (
                <IconButton
                  color='primary'
                  aria-label='upload picture'
                  component='label'
                  onClick={() => {
                    if (audience._id) handlers.handleAudienceStatusChange(audience._id);
                  }}
                >
                  <EditIcon fontSize='large' />
                </IconButton>
              )}
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
      <AudienceStatusUpdateOrganism
        status={status}
        setStatus={setStatus}
        isOpen={updateStatusDialogIsOpen}
        handleAccept={() => {
          setUpdateStatusDialogIsOpen(false);
        }}
        handleClose={() => {
          setUpdateStatusDialogIsOpen(false);
        }}
        setIsOpen={setUpdateStatusDialogIsOpen}
      />
    </>
  );
}
