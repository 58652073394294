import { Helmet } from 'react-helmet';
import Bubble from '../components/Bubble';

// import CompetitorEntryImg from '../assets/inscripcion.webp';
// import TrainersEntryImg from '../assets/trainers.jpg';
// import EntriesListImg from '../assets/inscriptos.webp';

export default function EntryPage() {
  const bubbles: {
    text: string;
    img: string;
    link: string;
    openInBlank: boolean;
  }[] = [
    {
      text: 'Formulario de Inscripción Competidores',
      img: '/inscripcion.webp',
      link: 'https://docs.google.com/forms/d/e/1FAIpQLSerY1UAp1oR9gsHgZDvA7ujgIye5BhklsWePi45E9DboglNiw/viewform?usp=sf_link',
      openInBlank: true,
    },
    // {
    //   text: 'Formulario de Inscripción Entrenadores',
    //   img: '/trainers.jpg',
    //   link: 'https://docs.google.com/forms/d/e/1FAIpQLSeZx0cE_xgEyZma8V2q3gXSIqdTKSCOjAvwy5oKOZ-Vvgjrgw/viewform?usp=sf_link',
    //   openInBlank: true,
    // },
    {
      text: 'Listado de Inscriptos',
      img: '/inscriptos.webp',
      link: 'https://docs.google.com/spreadsheets/d/1Jf86UW1KL7dl2xIUinWbw2eF7rZIUQldS9gWoamYhSc/edit?usp=sharing',
      openInBlank: true,
    },
  ];

  return (
    <div className='flex justify-evenly justify-items-center'>
      <Helmet>
        <title>Inscripción | Campeonato Velafest</title>
        <meta
          name='description'
          content='En esta pagina podrás encontrar los links para inscribirte en el Campeonato Velafest. El campeonato Velafest es un campeonato que se realiza en el rio de la plata, organizado en conjunto ente el Club de Veleros Barlovento y el Club Náutico Albatros.'
        />
      </Helmet>
      {/* {bubbles.length !== 0 &&
        <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 justify-evenly justify-items-center my-12 md:m-18 xl:m-24 overflow-hidden">
          {bubbles.map((bubble) => {
            return (
              <div className="basis-1/3 w-fit justify-center flex">
                <Bubble text={bubble.text} img={bubble.img} link={bubble.link} openInBlank={bubble.openInBlank}/>
              </div>
            )
          })}
        </div>
      } */}
      {bubbles.length !== 0 && (
        <div className='w-full justify-center overflow-hidden'>
          <div className='flex flex-row flex-wrap w-full justify-center py-8 '>
            {bubbles.map((bubble, index: number) => {
              return (
                <div
                  className='basis-1 md:basis-1/2 lg:basis-1/3 w-fit justify-center flex'
                  key={index}
                >
                  <Bubble
                    text={bubble.text}
                    img={bubble.img}
                    link={bubble.link}
                    openInBlank={bubble.openInBlank}
                  />
                </div>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
}
