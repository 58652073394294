import NavBar from '../components/NavBar';
import { Outlet } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import { Helmet } from 'react-helmet';

export default function Layout() {
  const [cookies, , removeCookie] = useCookies<'jwt', { jwt: string }>(['jwt']);

  if (cookies.jwt) {
    const JWT: string = cookies.jwt;

    const jwtPayload = JSON.parse(window.atob(JWT.split('.')[1]));
    if (Date.now() >= jwtPayload.exp * 1000) {
      removeCookie('jwt', { path: '/' });
      window.location.href =
        '/vf-admin/logout?errorMessage=Sesion%20vencida.%20Vuelva%20a%20iniciarla&redirectURI=' +
        encodeURI(window.location.href);
    }
  }

  const navbarPages = {
    home: {
      title: 'Inicio',
      url: '/',
      isVisible: true,
      adminRoute: false,
    },
    entry: {
      title: 'Inscripción',
      url: '/inscripcion',
      isVisible: true,
      adminRoute: false,
    },
    documents: {
      title: 'Documentos',
      url: '/documentos',
      isVisible: true,
      adminRoute: false,
    },
    results: {
      title: 'Resultados',
      url: '/resultados',
      isVisible: true,
      adminRoute: false,
    },
    requests: {
      title: 'Solicitudes',
      url: '/solicitudes',
      isVisible: true,
      adminRoute: false,
    },
    // velafestOrza: {
    //   title: 'Vela Fest 2023 Orza',
    //   url: 'https://2023.velafest.com.ar',
    //   isVisible: true,
    //   adminRoute: false,
    // },
    previosEditions: {
      title: 'Ediciones Previas',
      url: '/previous',
      isVisible: true,
      adminRoute: false,
    },
    logout: {
      title: 'Logout',
      url: 'vf-admin/logout',
      isVisible: true,
      adminRoute: true,
    },
  };

  // useEffect(() => {
  //   fetch('http://localhost:3001/documents/enabled',
  //   {
  //     mode: 'cors'
  //   })
  //   .then(res => {
  //     return res.json();
  //   })
  //   .then(res => {
  //     if (res.data.enabled) {
  //       setNavbarPages((existingData) => {
  //         return (
  //           {
  //             ...existingData,
  //             documents: {
  //               ...existingData.documents,
  //               isVisible: true
  //             }
  //           }
  //         )
  //       })
  //     }
  //   });
  // }, [])

  // useEffect(() => {
  //   new VFResults().resultsAreEnabled()
  //   .then(res => {
  //     if (res) {
  //       setNavbarPages((existingData) => {
  //         return (
  //           {
  //             ...existingData,
  //             results: {
  //               ...existingData.results,
  //               isVisible: true
  //             }
  //           }
  //         )
  //       })
  //     }
  //   })
  //   .catch((reason) => {
  //     showAlert({
  //       type: 'error',
  //       message: reason.toString()
  //     })
  //   })
  // }, [])

  return (
    <div className='flex flex-col h-screen'>
      <Helmet>
        <meta httpEquiv='Content-Type' content='text/html; charset=utf-8' />
        <meta name='viewport' content='width=device-width, initial-scale=1' />
        <meta
          name='description'
          content='Bienvenido! El campeonato Velafest es un campeonato que se realiza en el rio de la plata, organizado en conjunto ente el Club de Veleros Barlovento y el Club Náutico Albatros.'
        />
        <meta name='theme-color' content='#008f68' />
        <title>Inicio | Campeonato Velafest</title>
      </Helmet>
      <NavBar pages={navbarPages} />
      <Outlet />
      <div id='alertDiv' />
    </div>
  );
}
