import { IVFBasicAuthRes, IVFUser } from './IAuthenticationModule';
import { Config } from '../config';

export class VFAuth {
  basicAuth(user: IVFUser): Promise<IVFBasicAuthRes> {
    return fetch(`${Config.urls.modules.authentication}/login`, {
      mode: 'cors',
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(user),
    })
      .then((res) => {
        switch (res.status) {
          case 201:
            return res.json();
            break;

          case 401:
            throw new Error('Unauthorized');
            break;

          default:
            throw new Error(`Unknown error. Status code: ${res.status} ${res.statusText}`);
            break;
        }
      })
      .then((res) => {
        return res.data;
      });
  }
}
