import { TextField, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import {
  changeNewRequestFormValues,
  selectAllNewRequestFormValues,
} from '../../../../redux/features/requests/requestsSlice';
import CompetitorPartial from './CompetitorPartial';

export default function NewEquipmentChangePartial() {
  const newEquipmentChangeFormValues = useSelector(selectAllNewRequestFormValues);
  const dispatch = useDispatch();

  const handlers = {
    handleRequesterBoatCategoryChange: (newValue: string) => {
      const tempRequester = { ...newEquipmentChangeFormValues.requesterBoat };
      tempRequester.category = newValue;

      dispatch(
        changeNewRequestFormValues({
          key: 'requesterBoat',
          value: tempRequester,
        }),
      );
    },
    handleRequesterBoatSailNumberChange: (newValue: string) => {
      const tempRequester = { ...newEquipmentChangeFormValues.requesterBoat };
      tempRequester.sailNumber = newValue;

      dispatch(
        changeNewRequestFormValues({
          key: 'requesterBoat',
          value: tempRequester,
        }),
      );
    },
  };

  return (
    <>
      {/* Barco que pide la revision */}
      <br />
      <br />
      <Typography sx={{ fontWeight: 'bold', wordBreak: 'break-word' }}>
        {'Barco que pide revision de resultados'}
      </Typography>
      <CompetitorPartial
        categoryValue={newEquipmentChangeFormValues.requesterBoat.category}
        handleCategoryChange={handlers.handleRequesterBoatCategoryChange}
        sailNumberValue={newEquipmentChangeFormValues.requesterBoat.sailNumber}
        handleSailNumberChange={handlers.handleRequesterBoatSailNumberChange}
        isAudience={false}
      />
      <br />
      <Typography sx={{ fontWeight: 'bold', wordBreak: 'break-word' }}>{'Solicitud'}</Typography>
      <TextField
        label='Solicitud'
        value={newEquipmentChangeFormValues.request}
        onChange={(e) => {
          dispatch(
            changeNewRequestFormValues({
              key: 'request',
              value: e.target.value as string,
            }),
          );
        }}
        multiline
        rows={4}
        fullWidth
      />
    </>
  );
}
