import { MobileDateTimePicker } from '@mui/x-date-pickers/MobileDateTimePicker';
import TextField from '@mui/material/TextField';

import { useDispatch, useSelector } from 'react-redux';
import {
  selectAllFormData,
  changeFormValue
} from '../../../../redux/features/requests/audiences/audiencesSlice';

export default function PendingStatusChangeFormPartial() {
  const formValues = useSelector(selectAllFormData);
  const dispatch = useDispatch();

  const handleChangeDateTime = (newValue: string | null) => {
    dispatch(
      changeFormValue({
        key: 'date',
        value: newValue
      })
    );
  };

  return (
    // <MobileDateTimePicker
    //   label="Dia y hora de la audiencia"
    //   className="w-full"
    //   value={formValues.date ? new Date(formValues.date) : null}
    //   onChange={handleChangeDateTime}
    //   renderInput={(params) => <TextField {...params} />}
    //   inputFormat="dd/MM/yyyy HH:mm"
    //   mask="__/__/____ __:__"
    //   ampm={false}
    //   ampmInClock={false}
    // />
    <TextField 
      label="Dia y hora de la audiencia"
      className="w-full"
      value={formValues.date}
      onChange={(e) => handleChangeDateTime(e.target.value)}
    />
  );
}
