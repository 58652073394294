import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import Layout from './pages/Layout';
import HomePage from './pages/HomePage';
import DocumentsPage from './components/pages/DocumentsPage';
import NoPage from './pages/NoPage';
import EntryPage from './pages/EntryPage';
import LoginPage from './pages/admin/LoginPage';
import { CookiesProvider } from 'react-cookie';
import LogoutPage from './pages/admin/LogoutPage';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

import './index.css';

import { Config } from './lib/vf-sdk/config';
import ResultsPage from './components/pages/ResultsPage';
import SchedulePage from './components/pages/SchedulePage';
import AudienceRequestPage from './components/requests/audience/AudienceRequestPDFViewerPage';
import RequestsListPage from './components/requests/RequestsListPage';
import TrainerRegistrationsListPage from './pages/registrations/TrainerRegistrationsListPage';
import SailboatRegistrationsListPage from './pages/registrations/SailboatRegistrationsListPage';

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { es } from 'date-fns/locale';

import { store } from './redux/store';
import { Provider } from 'react-redux';
import PreviosEditionsPage from './components/previos-editions/PreviousEditions';
import { SailboatRegistrationPage } from './pages/registrations/SailboatRegistrationPage';

const queryClient = new QueryClient();

ReactDOM.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        <CookiesProvider>
          <LocalizationProvider locale={es} dateAdapter={AdapterDateFns}>
            <BrowserRouter>
              <Routes>
                <Route path='/' element={<Layout />}>
                  <Route index element={<HomePage />} />
                  <Route path='documentos' element={<DocumentsPage />} />
                  <Route path='inscripcion'>
                    <Route index element={<EntryPage />} />
                    {/* <Route path='competidor' element={<SailboatRegistrationPage />} />
                    <Route path='listado'>
                      <Route path='competidores' element={<SailboatRegistrationsListPage />} />
                      <Route path='entrenadores' element={<TrainerRegistrationsListPage />} />
                    </Route> */}
                  </Route>
                  <Route path='resultados' element={<ResultsPage />} />
                  <Route path='programa' element={<SchedulePage />} />
                  <Route path='solicitudes' element={<Navigate to='/solicitudes/audiencia' />} />
                  <Route path='solicitudes/:tipoSolicitud' element={<RequestsListPage />} />
                  <Route path='vf-admin'>
                    <Route path='login' element={<LoginPage />} />
                    <Route path='logout' element={<LogoutPage />} />
                  </Route>
                  <Route path='previous' element={<PreviosEditionsPage />} />
                  <Route path='*' element={<NoPage />} />
                </Route>
                <Route path='/solicitudes/audiencia/:id' element={<AudienceRequestPage />} />
              </Routes>
            </BrowserRouter>
          </LocalizationProvider>
        </CookiesProvider>
      </Provider>
    </QueryClientProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);
