import { MobileTimePicker } from '@mui/x-date-pickers/MobileTimePicker';

import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Divider from '@mui/material/Divider';

import { useDispatch, useSelector } from 'react-redux';
import {
  selectAllFormData,
  changeFormValue,
} from '../../../../redux/features/requests/audiences/audiencesSlice';
import { ChangeAudienceStatusFormValues } from '../../../../entities/requests/audience.entity';
import { Typography } from '@mui/material';
import { ChangeEvent } from 'react';

export default function ClosedStatusChangeFormPartial() {
  const formValues: ChangeAudienceStatusFormValues = useSelector(selectAllFormData);
  const dispatch = useDispatch();

  return (
    <>
      {/* Tiempo Limite */}
      <Divider className='py-1' textAlign='center'>
        Tiempo Limite
      </Divider>
      <br />
      <div className=' flex flex-col w-full'>
        <MobileTimePicker
          label='Hora limite para presentacion de protesta'
          value={
            formValues.presentationTimeLimit ? new Date(formValues.presentationTimeLimit) : null
          }
          onChange={(newValue: Date | null) => {
            dispatch(
              changeFormValue({
                key: 'presentationTimeLimit',
                value: newValue?.toString(),
              }),
            );
          }}
          renderInput={(params) => <TextField {...params} />}
          className='w-full'
        />
        <FormControlLabel
          control={
            <Checkbox
              onChange={(value) => {
                dispatch(
                  changeFormValue({
                    key: 'protestFiledInTime',
                    value: value.target.checked || false,
                  }),
                );
              }}
            />
          }
          label='La protesta, pedido de reparacion o reapertura se presento dentro del tiempo limite'
          value={formValues.protestFiledInTime}
        />
        <FormControlLabel
          control={
            <Checkbox
              onChange={(value) => {
                dispatch(
                  changeFormValue({
                    key: 'protestTimeLimitExtended',
                    value: value.target.checked || false,
                  }),
                );
              }}
            />
          }
          label='Se amplio el limite'
          value={formValues.protestTimeLimitExtended}
        />
      </div>

      {/* Asistentes a la audiencia */}
      <Divider className='py-1' textAlign='center'>
        Asistentes a la audiencia
      </Divider>
      <br />
      <div className='flex flex-col w-full'>
        <Typography>
          Protestante o parte que pide reparacion o reapertura represenatada por:
        </Typography>
        <TextField
          value={formValues.requesterRepresentedBy}
          onChange={(e: any) => {
            dispatch(
              changeFormValue({
                key: 'requesterRepresentedBy',
                value: e.target.value,
              }),
            );
          }}
          fullWidth
        />
        <Typography>
          Otra parte o barco que se considera para dar reparacion represenatada por:
        </Typography>
        <TextField
          value={formValues.protestees}
          onChange={(e: any) => {
            dispatch(
              changeFormValue({
                key: 'protestees',
                value: e.target.value,
              }),
            );
          }}
          fullWidth
        />
        <Typography>Nombre de los testigos:</Typography>
        <TextField
          value={formValues.witnesses}
          onChange={(e: any) => {
            dispatch(
              changeFormValue({
                key: 'witnesses',
                value: e.target.value,
              }),
            );
          }}
          fullWidth
        />
        <Typography>Interpretes:</Typography>
        <TextField
          value={formValues.interpreters}
          onChange={(e: any) => {
            dispatch(
              changeFormValue({
                key: 'interpreters',
                value: e.target.value,
              }),
            );
          }}
          fullWidth
        />
      </div>

      {/* Validez de la protesta */}
      <Divider className='py-1' textAlign='center'>
        Validez de la protesta
      </Divider>
      <br />
      <div className='flex flex-col w-full'>
        <FormControlLabel
          control={
            <Checkbox
              onChange={(value) => {
                dispatch(
                  changeFormValue({
                    key: 'validityObjectionChecked',
                    value: value.target.checked || false,
                  }),
                );
              }}
              checked={formValues.validityObjectionChecked}
            />
          }
          label='Objecion referida a parte interesada'
        />
        <TextField
          value={formValues.validityObjectionObservation}
          onChange={(e: any) => {
            dispatch(
              changeFormValue({
                key: 'validityObjectionObservation',
                value: e.target.value,
              }),
            );
          }}
          placeholder='Observacion'
          fullWidth
        />

        <FormControlLabel
          control={
            <Checkbox
              onChange={(value) => {
                dispatch(
                  changeFormValue({
                    key: 'validityTextRepresentsIncidentChecked',
                    value: value.target.checked || false,
                  }),
                );
              }}
              checked={formValues.validityTextRepresentsIncidentChecked}
            />
          }
          label='La protesta o pedido de reparacion, por escrito, identifica el incidente'
        />
        <TextField
          value={formValues.validityTextRepresentsIncidentObservation}
          onChange={(e: any) => {
            dispatch(
              changeFormValue({
                key: 'validityTextRepresentsIncidentObservation',
                value: e.target.value,
              }),
            );
          }}
          placeholder='Observacion'
          fullWidth
        />

        <FormControlLabel
          control={
            <Checkbox
              onChange={(value) => {
                dispatch(
                  changeFormValue({
                    key: 'validityHailedOutLoudChecked',
                    value: value.target.checked || false,
                  }),
                );
              }}
              checked={formValues.validityHailedOutLoudChecked}
            />
          }
          label="'Protesto' dicho en voz alta en la primera oportunidad razonable"
        />
        <TextField
          value={formValues.validityHailedOutLoudObservation}
          onChange={(e: any) => {
            dispatch(
              changeFormValue({
                key: 'validityHailedOutLoudObservation',
                value: e.target.value,
              }),
            );
          }}
          placeholder='Observacion'
          fullWidth
        />

        <FormControlLabel
          control={
            <Checkbox
              onChange={(value) => {
                dispatch(
                  changeFormValue({
                    key: 'validityNotifiedAtFirstOportunityChecked',
                    value: value.target.checked || false,
                  }),
                );
              }}
              checked={formValues.validityNotifiedAtFirstOportunityChecked}
            />
          }
          label='El protestante aviso en la primera oportunidad razonable (no se requeria aviso a viva voz)'
        />
        <TextField
          value={formValues.validityNotifiedAtFirstOportunityObservation}
          onChange={(e: any) => {
            dispatch(
              changeFormValue({
                key: 'validityNotifiedAtFirstOportunityObservation',
                value: e.target.value,
              }),
            );
          }}
          placeholder='Observacion'
          fullWidth
        />

        <FormControlLabel
          control={
            <Checkbox
              onChange={(value) => {
                dispatch(
                  changeFormValue({
                    key: 'validityRedFlagDisplayedChecked',
                    value: value.target.checked || false,
                  }),
                );
              }}
              checked={formValues.validityRedFlagDisplayedChecked}
            />
          }
          label='La bandera roja fue desplegada ostensiblemente en la primera oportunidad razonable'
        />
        <TextField
          value={formValues.validityRedFlagDisplayedObservation}
          onChange={(e: any) => {
            dispatch(
              changeFormValue({
                key: 'validityRedFlagDisplayedObservation',
                value: e.target.value,
              }),
            );
          }}
          placeholder='Observacion'
          fullWidth
        />

        <FormControlLabel
          control={
            <Checkbox
              onChange={(value) => {
                dispatch(
                  changeFormValue({
                    key: 'validityRedFlagSeenByRaceCommiteeChecked',
                    value: value.target.checked || false,
                  }),
                );
              }}
              checked={formValues.validityRedFlagSeenByRaceCommiteeChecked}
            />
          }
          label='La bandera roja fue vista por la comision de regata en la llegada'
        />
        <TextField
          value={formValues.validityRedFlagSeenByRaceCommiteeObservation}
          onChange={(e: any) => {
            dispatch(
              changeFormValue({
                key: 'validityRedFlagSeenByRaceCommiteeObservation',
                value: e.target.value,
              }),
            );
          }}
          placeholder='Observacion'
          fullWidth
        />

        <FormControlLabel
          control={
            <Checkbox
              onChange={(value) => {
                dispatch(
                  changeFormValue({
                    key: 'validityIsAudienceValid',
                    value: value.target.checked || false,
                  }),
                );
              }}
              checked={formValues.validityIsAudienceValid}
            />
          }
          label='La protesta o el pedido es valido/a, la audiencia continua'
        />
        <FormControlLabel
          control={
            <Checkbox
              onChange={(value) => {
                dispatch(
                  changeFormValue({
                    key: 'validityIsAudienceInvalid',
                    value: value.target.checked || false,
                  }),
                );
              }}
              checked={formValues.validityIsAudienceInvalid}
            />
          }
          label='La protesta o el pedido es invalido/a, la audiencia se cierra'
        />
      </div>

      {/* Aspectos procedimentales */}
      <Divider className='py-1' textAlign='center'>
      Aspectos procedimentales
      </Divider>
      <br />
      <div className='flex flex-col w-full'>
        <TextField
          value={formValues.proceduralMatters}
          onChange={(e: any) => {
            dispatch(
              changeFormValue({
                key: 'proceduralMatters',
                value: e.target.value,
              }),
            );
          }}
          placeholder='Aspectos procedimentales'
          fullWidth
          multiline
          rows={4}
        />
      </div>

      {/* Hechos considerados comprobados */}
      <Divider className='py-1' textAlign='center'>
        Hechos considerados comprobados
      </Divider>
      <br />
      <div className='flex flex-col w-full'>
        <TextField
          value={formValues.provedFacts}
          onChange={(e: any) => {
            dispatch(
              changeFormValue({
                key: 'provedFacts',
                value: e.target.value,
              }),
            );
          }}
          placeholder='Hechos considerados comprobados'
          fullWidth
          multiline
          rows={4}
        />
      </div>

      {/* Conclusiones y reglas que son de aplicacion */}
      <Divider className='py-1' textAlign='center'>
        Conclusiones y reglas que son de aplicacion
      </Divider>
      <br />
      <div className='flex flex-col w-full'>
        <TextField
          value={formValues.largeConclusion}
          onChange={(e: any) => {
            dispatch(
              changeFormValue({
                key: 'largeConclusion',
                value: e.target.value,
              }),
            );
          }}
          placeholder='Conclusiones y reglas que son de aplicacion'
          fullWidth
          multiline
          rows={4}
        />
      </div>

      {/* Resolucion */}
      <Divider className='py-1' textAlign='center'>
        Resolucion
      </Divider>
      <br />
      <div className='flex flex-col w-full'>
        <TextField
          value={formValues.longResolution}
          onChange={(e: any) => {
            dispatch(
              changeFormValue({
                key: 'longResolution',
                value: e.target.value,
              }),
            );
          }}
          placeholder='Resolucion completa'
          fullWidth
          multiline
          rows={4}
        />
        <br />
        <TextField
          value={formValues.shortResolution}
          onChange={(e: any) => {
            dispatch(
              changeFormValue({
                key: 'shortResolution',
                value: e.target.value,
              }),
            );
          }}
          placeholder='Resolucion corta'
          fullWidth
          multiline
        />
      </div>

      {/* Miembros de la comision de protestas */}
      <Divider className='py-1' textAlign='center'>
        Miembros de la comision de protestas
      </Divider>
      <br />
      <div className='flex flex-col w-full'>
        <TextField
          value={formValues.juryMembers}
          onChange={(e: any) => {
            dispatch(
              changeFormValue({
                key: 'juryMembers',
                value: e.target.value,
              }),
            );
          }}
          placeholder='Miembros de la comision de protestas'
          fullWidth
          multiline
          rows={2}
        />
      </div>

      {/* Observaciones */}
      <Divider className='py-1' textAlign='center'>
        Observaciones
      </Divider>
      <br />
      <div className='flex flex-col w-full'>
        <TextField
          value={formValues.observations}
          onChange={(e: any) => {
            dispatch(
              changeFormValue({
                key: 'observations',
                value: e.target.value,
              }),
            );
          }}
          placeholder='Observaciones'
          fullWidth
          multiline
          rows={2}
        />
      </div>
    </>
  );
}
