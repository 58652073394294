import {
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const classes = [
  // 'Optimist Timoneles',
  // 'Optimist Principiantes',
  // '420',
  // '29er',
  // 'ILCA 7',
  // 'ILCA 6',
  // 'ILCA 4',
  // 'Cadet',
  // 'F18',
  // 'Snipe',
  // 'Finn',
  // 'J70',
  'J24',
  'Grumete',
  'Soling',
  'Conte24'
];

export default function CompetitorPartial({
  categoryValue,
  handleCategoryChange,
  sailNumberValue,
  handleSailNumberChange,
  handleDeleteLine,
  enableDeleteLine,
  index,
  isAudience = false,
}: {
  categoryValue: any;
  handleCategoryChange: any;
  sailNumberValue: any;
  handleSailNumberChange: any;
  handleDeleteLine?: any;
  enableDeleteLine?: boolean;
  index?: number;
  isAudience?: boolean;
}) {
  return (
    <Grid container spacing={2} columns={14}>
      <Grid item xs={enableDeleteLine ? 6 : 7}>
        <FormControl sx={{ width: '100%' }}>
          <InputLabel id='boatcategory-select-label'>Categoria</InputLabel>
          <Select
            labelId='boatcategory-select-label'
            id='boatcategory-select'
            label='Categoria'
            variant='outlined'
            value={categoryValue}
            onChange={(e) => {
              if (index !== undefined) {
                handleCategoryChange(index, e.target.value as string);
              } else {
                handleCategoryChange(e.target.value as string);
              }
            }}
            required
            sx={{ width: '100%' }}
          >
            {isAudience && <MenuItem value={'CR'}>CR</MenuItem>}
            {isAudience && <MenuItem value={'CP'}>CP</MenuItem>}
            {classes.map((sailboatClass) => (
              <MenuItem value={sailboatClass} key={sailboatClass}>
                {sailboatClass}
              </MenuItem>
            ))}
            {/* <MenuItem value={'Conte 24'}>Conte 24</MenuItem>
            <MenuItem value={'Grumete'}>Grumete</MenuItem>
            <MenuItem value={'J24'}>J24</MenuItem>
            <MenuItem value={'Soling'}>Soling</MenuItem>
            <MenuItem value={'Star'}>Star</MenuItem> */}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={enableDeleteLine ? 6 : 7}>
        <TextField
          label='Numero de Vela'
          placeholder='ARG2587'
          value={sailNumberValue}
          onChange={(e) => {
            if (index !== undefined) {
              handleSailNumberChange(index, e.target.value as string);
            } else {
              handleSailNumberChange(e.target.value as string);
            }
          }}
          sx={{ width: '100%' }}
        />
      </Grid>
      {enableDeleteLine && (
        <Grid item xs={2}>
          <IconButton
            aria-label='delete'
            onClick={() => {
              handleDeleteLine(index);
            }}
          >
            <CloseIcon />
          </IconButton>
        </Grid>
      )}
    </Grid>
  );
}
