import { useCookies } from 'react-cookie';
import { Navigate, useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import './styles/audienceListPage.scss';
import { VFRequests } from '../../lib/vf-sdk/Requests/RequestsModule';
import RequestsListTemplate from './RequestsListTemplate';
import { ScreenLoadingOrganism } from '../UI/organisms/ScreenLoadingOrganism';
import { useGetAllAudiencesQuery } from '../../redux/service/velafestApi';
import { Audience } from '../../entities/requests/audience.entity';

export default function RequestsListPage() {
  const {
    data: audiences,
    isError: audiencesIsError,
    error: audiencesError,
    isLoading: audiencesIsLoading,
  } = useGetAllAudiencesQuery('');
  const [cookies] = useCookies(['jwt']);
  const { tipoSolicitud } = useParams();

  // ///TODO: Change the system so the page does not fully reload.
  // ///TODO: Find some other way to change more dynamically between request types
  // const getRequestData = (requestType: string) => {
  //   switch (requestType) {
  //     case 'audiencia':

  //     case 'resultados':
  //       return new VFRequests().getAudienceRequests

  //     case 'equipamiento':
  //       return new VFRequests().getAudienceRequests

  //     default:
  //       return new VFRequests().getAudienceRequests
  //   }
  // }

  // if (!cookies.jwt) {
  //   return <Navigate to="/vf-admin/login" />;
  // }

  if (audiencesIsLoading) {
    return <ScreenLoadingOrganism isOpen={audiencesIsLoading} />;
  }

  if (audiencesIsError) {
    if (audiencesError instanceof Error) {
      return <div>An error occurred: {audiencesError.message}</div>;
    }
  }

  if (audiences === undefined) {
    return <div>An error occurred: audiences is undefined</div>;
  }

  return <RequestsListTemplate requests={audiences.data} />;
}
