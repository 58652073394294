export default function Bubble(props: {
  text: string;
  img: string;
  link: string;
  openInBlank: boolean;
}) {
  let target: string;
  let rel: string;

  if (props.openInBlank) {
    target = '_blank';
    rel = 'noreferrer';
  } else {
    target = '';
    rel = '';
  }

  return (
    <a href={props.link} target={target} rel={rel}>
      <div className='w-96 h-96 flex flex-col items-center justify-start'>
        <img
          src={props.img}
          alt=''
          style={{ imageRendering: '-moz-crisp-edges' }}
          className='w-3/4 h-3/4 border-4 border-vforange rounded-full basis-3/4 object-cover '
        />
        <h1 className='text-vfblue text-3xl h-fit text-center font-bold'>{props.text}</h1>
      </div>
    </a>
  );
}
