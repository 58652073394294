export enum JuryPosition {
  'CHAIRMAN' = 'CHAIRMAN',
  'JURY' = 'JURY',
}

export interface AudienceResolution {
  _id?: string;

  retired?: boolean;

  timeLimit?: {
    timeLimit: string;
    presentedOnTime: boolean;
    presentationWindowExpanded: boolean;
  };

  participants?: {
    requesterRepresentedBy: string;
    protesteesRepresentedBy: string;
    witnesses: string;
    interpreters: string;
  };

  validity?: {
    interestedPartyObjected: {
      checked: boolean;
      observation: string;
    };
    theWrittenDeclarationDescribesTheIncident: {
      checked: boolean;
      observation: string;
    };
    hailedOutLoud: {
      checked: boolean;
      observation: string;
    };
    advicedAtFirstReasonableOpportunity: {
      checked: boolean;
      observation: string;
    };
    redFlagWasDisplayedAtFirstOpportunity: {
      checked: boolean;
      observation: string;
    };
    redFlagWasSeenByRaceCommitee: {
      checked: boolean;
      observation: string;
    };
    isValid: boolean;
  };

  proceduralMatters?: string;

  provedFacts?: string;

  conclusionAndRulesThatApply?: string;

  resolution?: string;

  shortResolution?: string;

  juryParticipants?: string;
}

export interface Audience {
  _id?: string;
  audienceId?: number;
  type: string;
  requester: {
    category: string;
    sailNumber: string;
  };
  participants: {
    category: string;
    sailNumber: string;
  }[];
  incident: {
    raceDate: string;
    raceNumber: string;
    dateTime: string;
    infringedRules: string;
    witnesses: {
      category: string;
      sailNumber: string;
    }[];
  };
  informed: {
    hail: {
      hailed: boolean;
      situation: string;
      wordsUsed: string;
    };
    flagDisplayed: {
      flagDisplayed: boolean;
      situation: string;
    };
    informedOtherWay: {
      informedOtherWay: boolean;
      situation: string;
    };
  };
  description: string;
  status?: {
    status: string;
    comment?: string;
    resolution?: AudienceResolution;
    date: string;
  }[];
}

export interface ChangeAudienceStatusFormValues {
  _id: string;
  status: AudienceStatusEnum;
  date: string | null;
  presentationTimeLimit: string | null;
  protestTimeLimitExtended: boolean;
  protestFiledInTime: boolean;
  requesterRepresentedBy: string | null;
  protestees: string | null;
  witnesses: string | null;
  interpreters: string | null;

  // Validity
  validityObjectionChecked: boolean;
  validityObjectionObservation: string | null;
  validityTextRepresentsIncidentChecked: boolean;
  validityTextRepresentsIncidentObservation: string | null;
  validityHailedOutLoudChecked: boolean;
  validityHailedOutLoudObservation: string | null;
  validityNotifiedAtFirstOportunityChecked: boolean;
  validityNotifiedAtFirstOportunityObservation: string | null;
  validityRedFlagDisplayedChecked: boolean;
  validityRedFlagDisplayedObservation: string | null;
  validityRedFlagSeenByRaceCommiteeChecked: boolean;
  validityRedFlagSeenByRaceCommiteeObservation: string | null;
  validityIsAudienceValid: boolean;
  validityIsAudienceInvalid: boolean;

  proceduralMatters: string | null;
  provedFacts: string | null;
  largeConclusion: string | null;

  longResolution: string | null;
  shortResolution: string | null;

  juryMembers: string | null;

  observations: string | null;
}

export enum AudienceStatusEnum {
  'PENDING' = 'PENDING',
  'SCHEDULED' = 'SCHEDULED',
  'PROCCESSED' = 'PROCCESSED',
  'RETIRED' = 'RETIRED',
}

export const AudienceStatusInLang = {
  es: {
    PENDING: 'Pendiente',
    SCHEDULED: 'Planificada',
    PROCCESSED: 'Tratada',
    RETIRED: 'Retirada',
  },
};
