import { useCookies } from 'react-cookie';
import { Navigate, useParams } from 'react-router-dom';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import { useContext, useEffect, useState } from 'react';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

import AudienceAccordionOrganism from './audience/AudienceAccordionOrganism';
import { Audience } from '../../entities/requests/audience.entity';
import NewRequestDialog from './newRequest/NewRequestDialog';
import ResultsReviewList from './resultsReview/ResultsReviewList';
import EquipmentChangeList from './equipmentChange/EquipmentChangeList';

export default function RequestsListTemplate({ requests }: { requests: Audience[] }) {
  const [sectionButtonFontWeight, setSectionButtonFontWeight] = useState([
    'normal',
    'normal',
    'normal',
  ]);
  const [cookies] = useCookies(['jwt']);
  const { tipoSolicitud } = useParams();

  const [tipoSolicitudLabel, setTipoSolicitudLabel] = useState('Audiencia');

  const [newRequestDialogIsOpen, setNewRequestDialogIsOpen] = useState<boolean>(false);

  const changeActiveSection = (activeIndex: number): void => {
    const changedArray: string[] = sectionButtonFontWeight.map((value) => 'normal');
    changedArray[activeIndex] = '900';

    setSectionButtonFontWeight(changedArray);
  };

  useEffect(() => {
    switch (tipoSolicitud) {
      case 'audiencia':
        changeActiveSection(0);
        setTipoSolicitudLabel('Audiencia');
        break;

      case 'resultados':
        changeActiveSection(1);
        setTipoSolicitudLabel('Revision de Resultados');
        break;

      case 'equipamiento':
        changeActiveSection(2);
        setTipoSolicitudLabel('Cambio de Equipamiento');
        break;

      default:
        changeActiveSection(0);
        setTipoSolicitudLabel('Audiencia');
        break;
    }
  }, [tipoSolicitud]);

  // if (!cookies.jwt) {
  //   return <Navigate to={'/vf-admin/login?redirectURI=' + encodeURI(window.location.href)} />;
  // }

  return (
    <div className='flex flex-col items-center w-full h-full'>
      <h1 className='text-6xl font-bold text-vfblue p-4'>Solicitudes</h1>
      <Button
        style={{ color: '#0F215E', borderColor: '#0F215E', margin: '1rem' }}
        variant='outlined'
        startIcon={<AddCircleOutlineIcon />}
        onClick={() => {
          setNewRequestDialogIsOpen(true);
        }}
      >
        Nueva solicitud
      </Button>
      <div className='hidden sm:flex justify-center w-full px-4'>
        <ButtonGroup
          variant='text'
          size='large'
          aria-label='large text button group'
          orientation='horizontal'
        >
          <Button
            onClick={() => {
              changeActiveSection(0);
              window.location.href = '/solicitudes/audiencia';
            }}
            sx={{ color: '#E84D1D', fontWeight: sectionButtonFontWeight[0] }}
          >
            Audiencias
          </Button>
          <Button
            onClick={() => {
              changeActiveSection(1);
              window.location.href = '/solicitudes/resultados';
            }}
            sx={{ color: '#E84D1D', fontWeight: sectionButtonFontWeight[1] }}
          >
            Revision de resultados
          </Button>
          <Button
            onClick={() => {
              changeActiveSection(2);
              window.location.href = '/solicitudes/equipamiento';
            }}
            sx={{ color: '#E84D1D', fontWeight: sectionButtonFontWeight[2] }}
          >
            Cambio de equipo
          </Button>
        </ButtonGroup>
      </div>
      <div className='sm:hidden flex justify-center w-full px-4'>
        <ButtonGroup
          variant='text'
          size='large'
          aria-label='large text button group'
          orientation='vertical'
        >
          <Button
            onClick={() => {
              changeActiveSection(0);
              window.location.href = '/solicitudes/audiencia';
            }}
            sx={{ color: '#E84D1D', fontWeight: sectionButtonFontWeight[0] }}
          >
            Audiencias
          </Button>
          <Button
            onClick={() => {
              changeActiveSection(1);
              window.location.href = '/solicitudes/resultados';
            }}
            sx={{ color: '#E84D1D', fontWeight: sectionButtonFontWeight[1] }}
          >
            Revision de resultados
          </Button>
          <Button
            onClick={() => {
              changeActiveSection(2);
              window.location.href = '/solicitudes/equipamiento';
            }}
            sx={{ color: '#E84D1D', fontWeight: sectionButtonFontWeight[2] }}
          >
            Cambio de equipo
          </Button>
        </ButtonGroup>
      </div>
      {tipoSolicitud === 'audiencia' && (
        <div className='flex flex-col justify-center p-8 md:w-3/4 lg:w-1/2 w-full'>
          {requests.map((request: Audience, index: number) => (
            <AudienceAccordionOrganism audience={request} isAdmin={true} key={index} />
          ))}
        </div>
      )}
      {tipoSolicitud === 'resultados' && <ResultsReviewList />}
      {tipoSolicitud === 'equipamiento' && <EquipmentChangeList />}
      <NewRequestDialog isOpen={newRequestDialogIsOpen} setIsOpen={setNewRequestDialogIsOpen} />
    </div>
  );
}
