import { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { VFResults } from '../../lib/vf-sdk/Results/ResultsModule';
import ResultsTemplate from '../templates/ResultsTemplate';
import { ScreenLoadingOrganism } from '../UI/organisms/ScreenLoadingOrganism';

export default function ResultsPage() {
  const [isPageLoading, setIsPageLoading] = useState<boolean>(true);

  const [results, setResults] = useState<any>([]);
  const [sailingClasses, setSailingClasses] = useState<string[]>([]);
  const [isAdmin, setIsAdmin] = useState<boolean>(false);
  const [cookies] = useCookies(['jwt']);

  const htmlHead: {
    title: string;
    description: string;
    shouldIndex: boolean;
  } = {
    title: 'Resultados | Campeonato Velafest',
    description:
      'Resultados del campeonato Velafest. El campeonato Velafest es un campeonato que se realiza en el rio de la plata, organizado en conjunto ente el Club de Veleros Barlovento y el Club Nautico Albatros.',
    shouldIndex: true,
  };

  useEffect(() => {
    // Set isAdmin state
    if (cookies.jwt) {
      setIsAdmin(true);
    }

    // Fetch Documents
    new VFResults().getAllResults().then((results) => {
      setResults(results);

      setIsPageLoading(false);
    });

    // Fetch Sailing Classes
    setSailingClasses([
      // 'Optimist Timonels',
      // 'Optimist Principiantes',
      // '420',
      // '29er',
      // 'ILCA 7',
      // 'ILCA 6',
      // 'ILCA 4',
      // 'Cadet',
      // 'F18',
      // 'Snipe',
      // 'Finn',
      // 'J70',
      'J24',
      'Grumete',
      'Soling',
      'Conte24'
    ]);
  }, [cookies.jwt]);

  if (isPageLoading) {
    return <ScreenLoadingOrganism isOpen={isPageLoading} />;
  }

  return (
    <ResultsTemplate
      results={results}
      sailingClasses={sailingClasses}
      setResults={setResults}
      isAdmin={isAdmin}
      htmlHead={htmlHead}
    />
  );
}
