import { faLink } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ResultsTableOrganism from '../UI/organisms/ResultsTableOrganism';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Autocomplete from '@mui/material/Autocomplete';
import { useCookies } from 'react-cookie';
import { showAlert } from '../../utils/showAlert';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { useState } from 'react';
import { Helmet } from 'react-helmet';
import { IResultRes } from '../../lib/vf-sdk/Results/IResultsModule';
import { VFResults } from '../../lib/vf-sdk/Results/ResultsModule';
import Paper from '@mui/material/Paper';
import { Divider, IconButton } from '@mui/material';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import CancelIcon from '@mui/icons-material/Cancel';

const defaultNewResultValue = {
  sailingClass: '',
  url: '',
  file: undefined,
  isSubmitting: false
}

export default function ResultsTemplate({
  results,
  sailingClasses,
  setResults,
  isAdmin,
  htmlHead,
}: {
  results: any[];
  sailingClasses: string[];
  setResults: any;
  isAdmin: boolean;
  htmlHead: {
    title: string;
    description: string;
    shouldIndex: boolean;
  };
}) {
  const [cookies, , removeCookie] = useCookies(['jwt']);

  const [newResultDialogIsOpen, setNewResultDialogIsOpen] = useState<boolean>(false);
  const [newResultFormValues, setNewResultFormValues] = useState<{
    sailingClass: string;
    url: string;
    file: any;
    isSubmitting: boolean;
  }>(defaultNewResultValue);

  const [deleteResultDialogIsOpen, setDeleteResultDialogIsOpen] = useState<boolean>(false);
  const [deleteResultId, setDeleteResultId] = useState<string>();

  const handlers = {
    newResult: {
      formInputChange: (e: any, fieldName = 'PLACEHOLDER', fieldValue: any = 'PLACEHOLDER') => {
        const name = fieldName !== 'PLACEHOLDER' ? fieldName : e.target.name;
        const value = fieldValue !== 'PLACEHOLDER' ? fieldValue : e.target.value;

        setNewResultFormValues({
          ...newResultFormValues,
          [name]: value,
        });
      },
      formSubmit: async (e: any) => {
        e.preventDefault();

        
        const data = {
          sailingClass: newResultFormValues.sailingClass,
          url: newResultFormValues.url,
          file: newResultFormValues.file
        };
        
        if (data.file !== undefined) {
          showAlert({
            type: 'info',
            message: 'Subiendo archivo. Espere porfavor...'
          })
          await new VFResults()
            .uploadResult({
              sailingClass: data.sailingClass,
              file: data.file
            }, {
              bearerToken: cookies.jwt,
            })
            .then((res) => {
              if (!res.success) {
                throw new Error('No se ha podido crear el documento');
              }
  
              showAlert({
                type: 'success',
                message: 'El resultado se ha creado con exito',
              });
  
              try {
                setResults([res.result, ...results]);
              } catch (e) {
                showAlert({
                  type: 'error',
                  message: 'Se ha creado el documento, pero no se ha podido recargar la lista',
                });
              }
  
              setNewResultDialogIsOpen(false);
            })
            .catch((reason) => {
              if (reason === 401) {
                removeCookie('jwt', { path: '/' });
                window.location.href =
                  '/vf-admin/logout?errorMessage=Sesion%20vencida.%20Vuelva%20a%20iniciarla';
              }
  
              showAlert({
                type: 'danger',
                message: reason,
              });
            });
        } else {
          await new VFResults()
            .createResult({
              sailingClass: data.sailingClass,
              url: data.url
            }, {
              bearerToken: cookies.jwt,
            })
            .then((res) => {
              if (!res.success) {
                throw new Error('No se ha podido crear el documento');
              }
  
              showAlert({
                type: 'success',
                message: 'El resultado se ha creado con exito',
              });
  
              try {
                setResults([res.result, ...results]);
              } catch (e) {
                showAlert({
                  type: 'error',
                  message: 'Se ha creado el documento, pero no se ha podido recargar la lista',
                });
              }
  
              setNewResultDialogIsOpen(false);
            })
            .catch((reason) => {
              if (reason === 401) {
                removeCookie('jwt', { path: '/' });
                window.location.href =
                  '/vf-admin/logout?errorMessage=Sesion%20vencida.%20Vuelva%20a%20iniciarla';
              }
  
              showAlert({
                type: 'danger',
                message: reason,
              });
            });
        }

        setNewResultFormValues(defaultNewResultValue);

      },
      buttonClick: (e: any) => {
        setNewResultDialogIsOpen(true);
      },
      cancelDialog: (e: any) => {
        setNewResultDialogIsOpen(false);
      },
    },
    deleteResult: {
      onClick: (e: any) => {
        setDeleteResultDialogIsOpen(true);
      },
      dialogAccept: (e: any) => {
        e.preventDefault();

        if (deleteResultId) {
          new VFResults()
            .deleteResult(deleteResultId, {
              bearerToken: cookies.jwt,
            })
            .then((res) => {
              if (!res.success) {
                showAlert({
                  type: 'error',
                  message: `No se ha podido eliminar el resultado ${deleteResultId}`,
                });
              }

              setDeleteResultDialogIsOpen(false);
              setResults(results.filter((result) => result.id !== deleteResultId));

              showAlert({
                type: 'success',
                message: `Se ha eliminado el resultado ${deleteResultId} satisfactoriamente`,
              });
            })
            .catch((reason) => {
              if (reason.toString() === 'Error: Unauthorized') {
                removeCookie('jwt', { path: '/' });

                window.location.href =
                  '/vf-admin/logout?errorMessage=Sesion%20vencida.%20Vuelva%20a%20iniciarla';
              }

              showAlert({
                type: 'danger',
                message: reason.toString(),
              });
            });
        }
      },
      dialogCancel: (e: any) => {
        setDeleteResultDialogIsOpen(false);
      },
      deleteButtonClick: (e: any, id: string) => {
        setDeleteResultId(id);
        setDeleteResultDialogIsOpen(true);
      },
    },
  };

  const resultsHeaders: any[] = ['Fecha de Publicacion', 'Resultado'];

  if (isAdmin) {
    resultsHeaders.push('Eliminar');
  }

  const resultsRows: any[][] = results.map((result: IResultRes, index: number) => {
    const row: any[] = [
      <p style={{ fontSize: '1rem' }} key={index}>
        {new Date(result.publishDate).toLocaleString('es-AR')}
      </p>,
      <a
        href={result.result.url}
        style={{ color: 'blue', fontSize: '1rem', textDecoration: 'none' }}
        target='_blank'
        rel='noreferrer'
        key={index}
      >
        <FontAwesomeIcon icon={faLink} style={{ color: 'black' }} />
        &nbsp;
        {result.result.title}
      </a>,
    ];

    if (isAdmin) {
      row.push(
        <Button
          variant='contained'
          color='error'
          onClick={(e: any) => {
            handlers.deleteResult.deleteButtonClick(e, result._id);
          }}
        >
          Eliminar
        </Button>,
      );
    }

    return row;
  });

  const tableData: {
    headers: any[];
    rows: any[][];
  } = {
    headers: resultsHeaders,
    rows: resultsRows,
  };

  const renderTable = () => {
    if (isAdmin) {
      return true;
    }

    if (results.length > 0) {
      return true;
    }

    return false;
  };

  return (
    <div className='flex flex-col items-center w-full h-full p-4'>
      <Helmet>
        <title>{htmlHead.title}</title>
        <meta name='description' content={htmlHead.description} />
        {!htmlHead.shouldIndex && <meta name='robots' content='noindex' />}
      </Helmet>
      {renderTable() && (
        <div className='flex flex-col items-center w-full h-full'>
          <h1 className='text-6xl font-bold text-vfblue'>Resultados</h1>
          <div className='p-6 md:w-3/4'>
            <div className='py-2'>
              {isAdmin && (
                <Button
                  variant='outlined'
                  startIcon={<AddCircleOutlineIcon />}
                  onClick={handlers.newResult.buttonClick}
                >
                  Nuevo Resultado
                </Button>
              )}
            </div>
            <div className='w-full'>
              <ResultsTableOrganism tableData={tableData} />
            </div>
          </div>
        </div>
      )}
      {!renderTable() && (
        <div className='flex justify-center items-center w-full h-full bg-vflightgrey'>
          <div className='flex justify-center items-center w-full h-full'>
            <Paper elevation={2}>
              <h1 className='p-6 text-2xl'>Todavia no hay resultados cargados</h1>
            </Paper>
          </div>
        </div>
      )}
      {/* New Result Form Dialog */}
      <Dialog open={newResultDialogIsOpen} onClose={handlers.newResult.cancelDialog}>
        <form onSubmit={handlers.newResult.formSubmit}>
          <DialogTitle>Nuevo Resultado</DialogTitle>
          <DialogContent>
            <Autocomplete
              options={sailingClasses}
              inputValue={newResultFormValues.sailingClass}
              onInputChange={(event, newInputValue) => {
                handlers.newResult.formInputChange(event, 'sailingClass', newInputValue);
              }}
              disableClearable
              renderInput={(params) => (
                <TextField
                  {...params}
                  autoFocus
                  margin='normal'
                  name='sailingClass'
                  id='sailingClass'
                  label='Clase'
                  type='text'
                  variant='outlined'
                  required
                />
              )}
            />
            <br />
            <TextField
              margin='normal'
              name='url'
              id='resultUrl'
              label='URL al Resultado'
              type='url'
              variant='outlined'
              onChange={handlers.newResult.formInputChange}
              disabled={newResultFormValues.file !== undefined}
            />
                        <br />
            <Divider textAlign='center'>o</Divider>
          <Button
            variant='outlined'
            component='label'
            sx={{ display: 'flex', justifyContent: 'space-evenly', marginTop: '5%' }}
            fullWidth
            disabled={newResultFormValues.url !== ''}
          >
            <FileUploadIcon />
            Subir Archivo
            <input
              type='file'
              name='file'
              id='resultFile'
              hidden
              onChange={(e: any) => {
                setNewResultFormValues({
                  ...newResultFormValues,
                  file: e.currentTarget.files[0]
                })
              }}
            />
          </Button>
          {newResultFormValues.file && (
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'start', alignItems: 'center', paddingTop: '3%', maxWidth: '231.64px' }} >
              <IconButton
                onClick={() => {
                  setNewResultFormValues({
                    ...newResultFormValues,
                    file: undefined
                  })
                }}
              >
                <CancelIcon />
              </IconButton>
              <p style={{ marginLeft: '2%', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                {newResultFormValues.file.name}
              </p>
            </div>
          )}
          </DialogContent>
          <DialogActions>
            <Button onClick={handlers.newResult.cancelDialog}>Cancelar</Button>
            <Button type='submit' disabled={newResultFormValues.isSubmitting}>Guardar</Button>
          </DialogActions>
        </form>
      </Dialog>
      {/* Remove Result Consent Dialog */}
      <Dialog open={deleteResultDialogIsOpen} onClose={handlers.deleteResult.dialogCancel}>
        <DialogTitle>Estas seguro?</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {`Estas seguro de que quieres eliminar el resultado: ${
              results.filter((result) => result.id === deleteResultId)[0]?.result.title
            }`}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handlers.deleteResult.dialogCancel}>Cancelar</Button>
          <Button color='error' variant='contained' onClick={handlers.deleteResult.dialogAccept}>
            Eliminar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
