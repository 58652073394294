import { configureStore } from '@reduxjs/toolkit';
import audiencesSlice from './features/requests/audiences/audiencesSlice';
import requestsSlice from './features/requests/requestsSlice';
import { velafestApi } from './service/velafestApi';

export const store = configureStore({
  reducer: {
    [velafestApi.reducerPath]: velafestApi.reducer,
    audiences: audiencesSlice,
    requests: requestsSlice,
  },
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: ['audiences/changeFormValues'],
      },
    }).concat(velafestApi.middleware);
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
