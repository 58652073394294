import { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { Config } from '../../lib/vf-sdk/config';
import DocumentsTemplate from '../templates/DocumentsTemplates';
import { ScreenLoadingOrganism } from '../UI/organisms/ScreenLoadingOrganism';

export default function DocumentsPage() {
  const [isPageLoading, setIsPageLoading] = useState<boolean>(true);

  const [documents, setDocuments] = useState<any>([]);
  const [isAdmin, setIsAdmin] = useState<boolean>(false);
  const [cookies] = useCookies(['jwt']);

  const htmlHead: {
    title: string;
    description: string;
    shouldIndex: boolean;
  } = {
    title: 'Documentos | Campeonato Velafest',
    description:
      'Documentos del campeonato Velafest. El campeonato Velafest es un campeonato que se realiza en el rio de la plata, organizado en conjunto ente el Club de Veleros Barlovento y el Club Nautico Albatros.',
    shouldIndex: true,
  };

  useEffect(() => {
    // Set isAdmin state
    if (cookies.jwt) {
      setIsAdmin(true);
    }

    setTimeout(() => {
      return;
    }, 10000);

    // Fetch Documents
    fetch(Config.urls.modules.documents, {
      mode: 'cors',
      method: 'GET',
    })
      .then((res) => {
        return res.json();
      })
      .then((res) => {
        setDocuments(res.data.documents.reverse());
        setIsPageLoading(false);
      });
  }, [cookies.jwt]);

  if (isPageLoading) {
    return <ScreenLoadingOrganism isOpen={isPageLoading} />;
  }

  return (
    <DocumentsTemplate
      documents={documents}
      setDocuments={setDocuments}
      isAdmin={isAdmin}
      htmlHead={htmlHead}
    />
  );
}
