import { useCookies } from 'react-cookie';
import { Navigate, useParams } from 'react-router-dom';

import './styles/pdfViewer.scss';

import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  PDFViewer,
  Image,
  usePDF,
  PDFDownloadLink,
} from '@react-pdf/renderer';

import VelaFestBanner from '../../../assets/velafest-logo.jpg';
import { VFRequests } from '../../../lib/vf-sdk/Requests/RequestsModule';

import { useQuery } from '@tanstack/react-query';
import { ScreenLoadingOrganism } from '../../UI/organisms/ScreenLoadingOrganism';
import { IAudienceRequest } from '../../../lib/vf-sdk/Requests/IRequestsModule';

import { format } from 'date-fns';
import { Button, Typography } from '@mui/material';

export default function AudienceRequestPDFViewerPage() {
  const [cookies] = useCookies(['jwt']);
  const { id } = useParams();

  const audience = useQuery({
    queryKey: ['audience', id],
    queryFn: () => {
      if (id) {
        return new VFRequests().getAudienceRequestById(id);
      }
    },
  });

  if (!cookies.jwt) {
    return <Navigate to={'/vf-admin/login?redirectURI=' + encodeURI(window.location.href)} />;
  }

  let audienceIsResolved = false;

  if (
    audience.data?.status[0].status == 'RETIRED' ||
    audience.data?.status[0].status == 'PROCCESSED'
  ) {
    audienceIsResolved = true;
  }

  // const audience = {
  //   "audienceId": id,
  //   "type": "protest-b2b",
  //   "requester": {
  //     "category": "420",
  //     "sailNumber": "URU53325"
  //   },
  //   "participants": [
  //     {
  //       "category": "420",
  //       "sailNumber": "ARG57027"
  //     }
  //   ],
  //   "incident": {
  //     "dateOfRace": "22/2",
  //     "raceNumber": 3,
  //     "dateTime": "R3 Largada extremo de la lancha",
  //     "infringedRules": "18.2",
  //     "witnesses": [
  //       {
  //         "category": "420",
  //         "sailNumber": "ARG57025"
  //       },
  //     ]
  //   },
  //   "informed": {
  //     "hail": {
  //       "hailed": true,
  //       "situation": "Instantaneamente luego de evadir el contacto",
  //       "wordsUsed": "PROTESTO!"
  //     },
  //     "flagDisplayed": false,
  //     "informedOtherWay": ""
  //   },
  //   "description": "Pocos segundos previo a la señal de partida, el barco ARG57027 estando a sotavento y comprometido del URU53325, decidio derivar la lancha para largar, pero no dejar suficiente lugar para que el URU53325 pase entre la lancha y ARG57027. Luego de multiples solicitudes el URU53325 evita el contacto al virar y largar varios segundos tarde, y se le grito PROTESTO! al ARG57027",
  //   "status": [
  //     {
  //       "status": "SCHEDULED",
  //       "comment": "Planificada para el 18/1/2023 a las 19:55hs",
  //       "date": "1-18-2023 17:42:12Z"
  //     },
  //     {
  //       "status": "PENDING",
  //       "comment": "",
  //       "date": "1-18-2023 17:13:48Z"
  //     }
  //   ]
  // }

  const styles = StyleSheet.create({
    page: {
      flexDirection: 'column',
      backgroundColor: '#fff',
    },
    header: {
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
    },
    headerTitle: {
      fontSize: '18px',
    },
    headerSection: {
      display: 'flex',
      width: '43%',
      height: '80%',
      margin: '3%',
      padding: '10px',
      border: '1px',
      justifyContent: 'center',
    },
    headerTextBold: {
      fontSize: '14px',
    },
    headerText: {
      fontSize: '12px',
      color: '#3f434a',
    },
    logo: {
      width: '250px',
      margin: '3%',
    },
    section: {
      display: 'flex',
      width: '95%',
      border: '1px',
      margin: '1%',
      padding: '3%',
    },
    bodyHeader: {
      fontSize: '14px',
    },
    bodyText: {
      fontSize: '12px',
      color: '#3f434a',
    },
  });

  const MyDocument = ({ audience }: { audience: IAudienceRequest | undefined }) => (
    <Document
      title={`Velafest 2023 Audiencia N${audience?.audienceId}`}
      author='Velafest 2023'
      subject={`Velafest 2023 Audiencia N${audience?.audienceId}`}
    >
      <Page size='A4' style={styles.page}>
        {/* HEADER */}
        <View style={styles.header} fixed>
          <Image src={VelaFestBanner} style={styles.logo} />
          <View style={styles.headerSection}>
            <Text style={styles.headerTitle}>Protesta de un barco a otro</Text>
            <View style={{ marginVertical: '10px', marginHorizontal: '0px' }}>
              <Text style={styles.headerTextBold}>
                Audiencia: <Text style={styles.headerText}>N{audience?.audienceId ?? '-'}</Text>
              </Text>
              <Text style={styles.headerTextBold}>
                Dia de la regata:{' '}
                <Text style={styles.headerText}>{audience?.incident.raceDate ?? '-'}</Text>
              </Text>
              <Text style={styles.headerTextBold}>
                Regata: <Text style={styles.headerText}>N°{audience?.incident.raceNumber ?? '-'}</Text>
              </Text>
              <Text style={styles.headerTextBold}>
                Fecha de recepcion:{' '}
                <Text style={styles.headerText}>
                  {audience?.status[audience?.status.length - 1].date ?? '-'}
                </Text>
              </Text>
            </View>
          </View>
        </View>
        {/* BODY */}
        {/* REQUESTER */}
        <Text style={{ marginLeft: '18px' }}>
          Barco que protesta, pide reparacion o reapertura:
        </Text>
        <View
          style={{
            display: 'flex',
            flexDirection: 'column',
            width: '95%',
            border: '1px',
            margin: '3%',
            marginVertical: '1%',
            padding: '3%',
            paddingVertical: '1%',
          }}
        >
          <View style={{ flexDirection: 'row' }}>
            <View style={{ flexBasis: '50%' }}>
              <Text style={styles.bodyHeader}>
                Categoria: <Text style={styles.bodyText}>{audience?.requester.category ?? '-'}</Text>
              </Text>
            </View>
            <View style={{ flexBasis: '50%' }}>
              <Text style={styles.bodyHeader}>
                Numero de vela:{' '}
                <Text style={styles.bodyText}>{audience?.requester.sailNumber ?? '-'}</Text>
              </Text>
            </View>
          </View>
        </View>
        {/* PARTICIPANTS */}
        <Text style={{ marginLeft: '18px' }}>
          Barcos protestados o considerados para una reparacion:
        </Text>
        <View
          style={{
            display: 'flex',
            flexDirection: 'column',
            width: '95%',
            border: '1px',
            margin: '3%',
            marginVertical: '1%',
            padding: '3%',
            paddingVertical: '1%',
          }}
        >
          {audience?.participants.map((participant: any, index: number) => {
            return (
              <View style={{ flexDirection: 'row' }} key={index}>
                <View style={{ flexBasis: '50%' }}>
                  <Text style={styles.bodyHeader}>
                    Categoria: <Text style={styles.bodyText}>{participant.category ?? '-'}</Text>
                  </Text>
                </View>
                <View style={{ flexBasis: '50%' }}>
                  <Text style={styles.bodyHeader}>
                    Numero de vela: <Text style={styles.bodyText}>{participant.sailNumber ?? '-'}</Text>
                  </Text>
                </View>
              </View>
            );
          })}
        </View>
        {/* WITNESSES */}
        <Text style={{ marginLeft: '18px' }}>Testigos:</Text>
        <View
          style={{
            display: 'flex',
            flexDirection: 'column',
            width: '95%',
            border: '1px',
            margin: '3%',
            marginVertical: '1%',
            padding: '3%',
            paddingVertical: '1%',
          }}
        >
          {audience?.incident.witnesses &&
            audience?.incident.witnesses.map((participant: any, index: number) => {
              return (
                <View style={{ flexDirection: 'row' }} key={index}>
                  <View style={{ flexBasis: '50%' }}>
                    <Text style={styles.bodyHeader}>
                      Categoria: <Text style={styles.bodyText}>{participant.category ?? '-'}</Text>
                    </Text>
                  </View>
                  <View style={{ flexBasis: '50%' }}>
                    <Text style={styles.bodyHeader}>
                      Numero de vela: <Text style={styles.bodyText}>{participant.sailNumber ?? '-'}</Text>
                    </Text>
                  </View>
                </View>
              );
            })}
          {!audience?.incident.witnesses && (
            <Text style={styles.bodyHeader}>No se presentaron testigos.</Text>
          )}
        </View>
        {/* INCIDENT */}
        <Text style={{ marginLeft: '18px' }}>Incidente:</Text>
        <View
          style={{
            display: 'flex',
            flexDirection: 'column',
            width: '95%',
            border: '1px',
            margin: '3%',
            marginVertical: '1%',
            padding: '3%',
            paddingVertical: '1%',
          }}
        >
          <Text style={styles.bodyHeader}>
            Hora y lugar del incidente:{' '}
            <Text style={styles.bodyText}>{audience?.incident.dateTime ?? '-'}</Text>
          </Text>
          <Text style={styles.bodyHeader}>
            Reglas que se habrian infringido:{' '}
            <Text style={styles.bodyText}>{audience?.incident.infringedRules ?? '-'}</Text>
          </Text>
        </View>
        {/* HAIL */}
        <Text style={{ marginLeft: '18px' }}>Aviso al protestado:</Text>
        <View
          style={{
            display: 'flex',
            flexDirection: 'column',
            width: '95%',
            border: '1px',
            margin: '3%',
            marginVertical: '1%',
            padding: '3%',
            paddingVertical: '1%',
          }}
        >
          <View style={{ flexDirection: 'row' }}>
            <View style={{ flexBasis: '40%' }}>
              <Text style={styles.bodyHeader}>
                Se avisó en voz alta?:{' '}
                <Text style={styles.bodyText}>{audience?.informed.hail.hailed ? 'SÍ' : 'NO'}</Text>
              </Text>
            </View>
            <View style={{ flexBasis: '60%' }}>
              <Text style={styles.bodyHeader}>
                Cuándo?: <Text style={styles.bodyText}>{audience?.informed.hail.situation ?? '-'}</Text>
              </Text>
            </View>
          </View>
          <Text style={styles.bodyHeader}>
            Palabras utilizadas:{' '}
            <Text style={styles.bodyText}>{audience?.informed.hail.wordsUsed ?? '-'}</Text>
          </Text>
          <View style={{ flexDirection: 'row', marginTop: '10px' }}>
            <View style={{ flexBasis: '40%' }}>
              <Text style={styles.bodyHeader}>
                Se desplego bandera roja?:{' '}
                <Text style={styles.bodyText}>
                  {audience?.informed.flagDisplayed.flagDisplayed ? 'SÍ' : 'NO'}
                </Text>
              </Text>
            </View>
            <View style={{ flexBasis: '60%' }}>
              <Text style={styles.bodyHeader}>
                Cuándo?:{' '}
                <Text style={styles.bodyText}>{audience?.informed.flagDisplayed.situation ?? '-'}</Text>
              </Text>
            </View>
          </View>
          <View style={{ flexDirection: 'row', marginTop: '10px' }}>
            <View style={{ flexBasis: '40%' }}>
              <Text style={styles.bodyHeader}>
                Se aviso de otra forma?:{' '}
                <Text style={styles.bodyText}>
                  {audience?.informed.informedOtherWay.informedOtherWay ? 'SÍ' : 'NO'}
                </Text>
              </Text>
            </View>
            <View style={{ flexBasis: '60%' }}>
              <Text style={styles.bodyHeader}>
                Como?:{' '}
                <Text style={styles.bodyText}>{audience?.informed.informedOtherWay.situation ?? '-'}</Text>
              </Text>
            </View>
          </View>
        </View>
        {/* DESCRIPTION */}
        <Text style={{ marginLeft: '18px' }}>Descripcion del incidente:</Text>
        <View
          style={{
            display: 'flex',
            flexDirection: 'column',
            width: '95%',
            border: '1px',
            margin: '3%',
            marginVertical: '1%',
            padding: '3%',
            paddingVertical: '1%',
          }}
        >
          <Text style={styles.bodyText}>{audience?.description ?? '-'}</Text>
        </View>
        {/* Resolucion */}
        {audienceIsResolved && (
          <>
            {/* Retiro de la protesta */}
            <Text style={{ marginLeft: '18px' }} break>
              Retiro de la protesta
            </Text>
            <View
              style={{
                display: 'flex',
                flexDirection: 'column',
                width: '95%',
                border: '1px',
                margin: '3%',
                marginVertical: '1%',
                padding: '3%',
                paddingVertical: '1%',
              }}
            >
              <View style={{ flexDirection: 'row' }}>
                <View>
                  <Text style={styles.bodyHeader}>
                    Se retira la protesta:{' '}
                    <Text style={styles.bodyText}>
                      {audience?.status[0].resolution?.retired ? 'Si' : 'No'}
                    </Text>
                  </Text>
                </View>
              </View>
            </View>

            {/* Limite de tiempo */}
            <Text style={{ marginLeft: '18px' }}>Limite de tiempo</Text>
            <View
              style={{
                display: 'flex',
                flexDirection: 'column',
                width: '95%',
                border: '1px',
                margin: '3%',
                marginVertical: '1%',
                padding: '3%',
                paddingVertical: '1%',
              }}
            >
              <View style={{ flexDirection: 'column' }}>
                <View>
                  <Text style={styles.bodyHeader}>
                    Hora limite para presentacion de protestas:{' '}
                    <Text style={styles.bodyText}>
                      {!Number.isNaN(new Date(audience?.status[0].resolution?.timeLimit?.timeLimit ?? '').getTime())
                        ? `${format(
                            new Date(audience?.status[0].resolution?.timeLimit?.timeLimit ?? ''),
                            'HH:mm',
                          )} hs`
                        : audience?.status[0].resolution?.timeLimit?.timeLimit ?? '-'}
                    </Text>
                  </Text>
                </View>
                <View style={{ marginTop: '10px' }}>
                  <Text style={styles.bodyHeader}>
                    {
                      'La protesta, el pedido de reparacion o de reapertura esta dentro del\n limite de tiempo: '
                    }
                    <Text style={styles.bodyText}>
                      {audience?.status[0].resolution?.timeLimit?.presentedOnTime ? 'Si' : 'No'}
                    </Text>
                  </Text>
                </View>
                <View>
                  <Text style={styles.bodyHeader}>
                    Se amplio el limite:{' '}
                    <Text style={styles.bodyText}>
                      {audience?.status[0].resolution?.timeLimit?.presentationWindowExpanded
                        ? 'Si'
                        : 'No'}
                    </Text>
                  </Text>
                </View>
              </View>
            </View>

            {/* Asistentes a la audiencia */}
            <Text style={{ marginLeft: '18px' }}>Asistentes a la audiencia</Text>
            <View
              style={{
                display: 'flex',
                flexDirection: 'column',
                width: '95%',
                border: '1px',
                margin: '3%',
                marginVertical: '1%',
                padding: '3%',
                paddingVertical: '1%',
              }}
            >
              <View style={{ flexDirection: 'column' }}>
                <View>
                  <Text style={styles.bodyHeader}>
                    Protestante o parte que pide reparacion representada por:
                  </Text>
                  <Text style={styles.bodyText}>
                    {audience?.status[0].resolution?.participants?.requesterRepresentedBy
                      ? audience?.status[0].resolution?.participants?.requesterRepresentedBy
                      : '................................................................................................................................................................'}
                  </Text>
                </View>
                <View>
                  <Text style={styles.bodyHeader}>
                    Otra parte o barco que se considera para dar una reparacion representada por:
                  </Text>
                  <Text style={styles.bodyText}>
                    {audience?.status[0].resolution?.participants?.protesteesRepresentedBy
                      ? audience?.status[0].resolution?.participants?.protesteesRepresentedBy
                      : '................................................................................................................................................................'}
                  </Text>
                </View>
                <View>
                  <Text style={styles.bodyHeader}>Nombres de los testigos:</Text>
                  <Text style={styles.bodyText}>
                    {audience?.status[0].resolution?.participants?.witnesses
                      ? audience?.status[0].resolution?.participants?.witnesses
                      : '................................................................................................................................................................'}
                  </Text>
                </View>
                <View>
                  <Text style={styles.bodyHeader}>Interpretes:</Text>
                  <Text style={styles.bodyText}>
                    {audience?.status[0].resolution?.participants?.interpreters
                      ? audience?.status[0].resolution?.participants?.interpreters
                      : '................................................................................................................................................................'}
                  </Text>
                </View>
              </View>
            </View>

            {/* Validez de la protesta */}
            <Text style={{ marginLeft: '18px' }}>Validez de la protesta</Text>
            <View
              style={{
                display: 'flex',
                flexDirection: 'column',
                width: '95%',
                border: '1px',
                margin: '3%',
                marginVertical: '1%',
                padding: '3%',
                paddingVertical: '1%',
              }}
            >
              <View style={{ flexDirection: 'column' }}>
                <View wrap={false}>
                  <Text style={styles.bodyHeader}>
                    Objecion referida a parte interesada:{' '}
                    <Text style={styles.bodyText}>
                      {audience?.status[0].resolution?.validity?.interestedPartyObjected.checked
                        ? 'Si'
                        : 'No'}
                    </Text>
                  </Text>
                  <Text style={styles.bodyHeader}>
                    Observacion:{' '}
                    <Text style={styles.bodyText}>
                      {audience?.status[0].resolution?.validity?.interestedPartyObjected.observation
                        ? audience?.status[0].resolution?.validity?.interestedPartyObjected
                            .observation
                        : ''}
                    </Text>
                  </Text>
                </View>
                <View style={{ marginTop: '15px' }} wrap={false}>
                  <Text style={styles.bodyHeader}>
                    La protesta o pedido de reparacion, por escrito, identifica el incidente:{' '}
                    <Text style={styles.bodyText}>
                      {audience?.status[0].resolution?.validity
                        ?.theWrittenDeclarationDescribesTheIncident.checked
                        ? 'Si'
                        : 'No'}
                    </Text>
                  </Text>
                  <Text style={styles.bodyHeader}>
                    Observacion:{' '}
                    <Text style={styles.bodyText}>
                      {audience?.status[0].resolution?.validity
                        ?.theWrittenDeclarationDescribesTheIncident.observation
                        ? audience?.status[0].resolution?.validity
                            ?.theWrittenDeclarationDescribesTheIncident.observation
                        : ''}
                    </Text>
                  </Text>
                </View>
                <View style={{ marginTop: '15px' }} wrap={false}>
                  <Text style={styles.bodyHeader}>
                    &apos;Protesto&apos; dicho en voz alta en la primera oportunidad razonable:{' '}
                    <Text style={styles.bodyText}>
                      {audience?.status[0].resolution?.validity?.hailedOutLoud.checked
                        ? 'Si'
                        : 'No'}
                    </Text>
                  </Text>
                  <Text style={styles.bodyHeader}>
                    Observacion:{' '}
                    <Text style={styles.bodyText}>
                      {audience?.status[0].resolution?.validity?.hailedOutLoud.observation
                        ? audience?.status[0].resolution?.validity?.hailedOutLoud.observation
                        : ''}
                    </Text>
                  </Text>
                </View>
                <View style={{ marginTop: '15px' }} wrap={false}>
                  <Text style={styles.bodyHeader}>
                    El protestante aviso en la primera oportunidad razonable (no se requeria el
                    aviso a viva voz):{' '}
                    <Text style={styles.bodyText}>
                      {audience?.status[0].resolution?.validity?.advicedAtFirstReasonableOpportunity
                        .checked
                        ? 'Si'
                        : 'No'}
                    </Text>
                  </Text>
                  <Text style={styles.bodyHeader}>
                    Observacion:{' '}
                    <Text style={styles.bodyText}>
                      {audience?.status[0].resolution?.validity?.advicedAtFirstReasonableOpportunity
                        .observation
                        ? audience?.status[0].resolution?.validity
                            ?.advicedAtFirstReasonableOpportunity.observation
                        : ''}
                    </Text>
                  </Text>
                </View>
                <View style={{ marginTop: '15px' }} wrap={false}>
                  <Text style={styles.bodyHeader}>
                    La bandera roja fue desplegada ostensiblemente en la primera oportunidad
                    razonable:{' '}
                    <Text style={styles.bodyText}>
                      {audience?.status[0].resolution?.validity
                        ?.redFlagWasDisplayedAtFirstOpportunity.checked
                        ? 'Si'
                        : 'No'}
                    </Text>
                  </Text>
                  <Text style={styles.bodyHeader}>
                    Observacion:{' '}
                    <Text style={styles.bodyText}>
                      {audience?.status[0].resolution?.validity
                        ?.redFlagWasDisplayedAtFirstOpportunity.observation
                        ? audience?.status[0].resolution?.validity
                            ?.redFlagWasDisplayedAtFirstOpportunity.observation
                        : ''}
                    </Text>
                  </Text>
                </View>
                <View style={{ marginTop: '15px' }} wrap={false}>
                  <Text style={styles.bodyHeader}>
                    La bandera roja fue vista por la comision de regata en la llegada:{' '}
                    <Text style={styles.bodyText}>
                      {audience?.status[0].resolution?.validity?.redFlagWasSeenByRaceCommitee
                        .checked
                        ? 'Si'
                        : 'No'}
                    </Text>
                  </Text>
                  <Text style={styles.bodyHeader}>
                    Observacion:{' '}
                    <Text style={styles.bodyText}>
                      {audience?.status[0].resolution?.validity?.redFlagWasSeenByRaceCommitee
                        .observation
                        ? audience?.status[0].resolution?.validity?.redFlagWasSeenByRaceCommitee
                            .observation
                        : ''}
                    </Text>
                  </Text>
                </View>
                <View style={{ marginTop: '15px' }} wrap={false}>
                  <Text style={styles.bodyHeader}>
                    La protesta o el pedido:{' '}
                    <Text style={styles.bodyText}>
                      {audience?.status[0].resolution?.validity?.isValid
                        ? 'es valido/a, la audiencia continua'
                        : 'es invalido/a, se cierra la audiencia'}
                    </Text>
                  </Text>
                </View>
              </View>
            </View>

            {/* Aspectos procedimentales */}
            <Text style={{ marginLeft: '18px' }}>Aspectos procedimentales</Text>
            <View
              style={{
                display: 'flex',
                flexDirection: 'column',
                width: '95%',
                border: '1px',
                margin: '3%',
                marginVertical: '1%',
                padding: '3%',
                paddingVertical: '1%',
              }}
            >
              <View style={{ flexDirection: 'column' }}>
                <View>
                  <Text style={styles.bodyText}>{audience?.status[0].resolution?.proceduralMatters ?? '-'}</Text>
                </View>
              </View>
            </View>

            {/* Hechos considerados comprobados */}
            <Text style={{ marginLeft: '18px' }}>Hechos considerados comprobados</Text>
            <View
              style={{
                display: 'flex',
                flexDirection: 'column',
                width: '95%',
                border: '1px',
                margin: '3%',
                marginVertical: '1%',
                padding: '3%',
                paddingVertical: '1%',
              }}
            >
              <View style={{ flexDirection: 'column' }}>
                <View>
                  <Text style={styles.bodyText}>{audience?.status[0].resolution?.provedFacts ?? '-'}</Text>
                </View>
              </View>
            </View>

            {/* Conclusion y reglas que son de aplicacion */}
            <Text style={{ marginLeft: '18px' }}>Conclusion y reglas que son de aplicacion</Text>
            <View
              style={{
                display: 'flex',
                flexDirection: 'column',
                width: '95%',
                border: '1px',
                margin: '3%',
                marginVertical: '1%',
                padding: '3%',
                paddingVertical: '1%',
              }}
            >
              <View style={{ flexDirection: 'column' }}>
                <View>
                  <Text style={styles.bodyText}>
                    {audience?.status[0].resolution?.conclusionAndRulesThatApply ?? '-'}
                  </Text>
                </View>
              </View>
            </View>

            {/* Resolucion */}
            <Text style={{ marginLeft: '18px' }}>Resolucion</Text>
            <View
              style={{
                display: 'flex',
                flexDirection: 'column',
                width: '95%',
                border: '1px',
                margin: '3%',
                marginVertical: '1%',
                padding: '3%',
                paddingVertical: '1%',
              }}
            >
              <View style={{ flexDirection: 'column' }}>
                <View>
                  <Text style={styles.bodyText}>{audience?.status[0].resolution?.resolution ?? '-'}</Text>
                </View>
              </View>
            </View>

            {/* Miembros de la comision de protesta */}
            <Text style={{ marginLeft: '18px' }}>Miembros de la comision de protesta</Text>
            <View
              style={{
                display: 'flex',
                flexDirection: 'column',
                width: '95%',
                border: '1px',
                margin: '3%',
                marginVertical: '1%',
                padding: '3%',
                paddingVertical: '1%',
              }}
            >
              <View style={{ flexDirection: 'column' }}>
                <View>
                  <Text style={styles.bodyText}>
                    {audience?.status[0].resolution?.juryParticipants ?? '-'}
                  </Text>
                </View>
              </View>
            </View>
          </>
        )}
      </Page>
    </Document>
  );

  if (audience.isLoading) {
    return <ScreenLoadingOrganism isOpen={true} />;
  }

  if (!audience.isLoading) {
    console.log(audience.data);
  }

  return (
    <>
      {window.screen.width >= 768 && (
        <div>
          <PDFViewer className='sm:flex hidden w-screen h-screen'>
            <MyDocument audience={audience.data} />
          </PDFViewer>
        </div>
      )}
      {window.screen.width < 768 && (
        <PDFDownloadLink
          document={<MyDocument audience={audience.data} />}
          fileName={`Audiencia N${audience.data?.audienceId}.pdf`}
        >
          {({ blob, url, loading, error }) =>
            loading ? (
              'Loading document...'
            ) : (
              <div className='flex w-screen h-screen justify-center items-center'>
                <Typography variant='h1'>Descargar PDF</Typography>
              </div>
            )
          }
        </PDFDownloadLink>
      )}
    </>
  );
}
