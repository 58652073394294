import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

export default function DocumentsTableOrganism({
  tableData
}: {
  tableData: {
    headers: any[];
    rows: any[][];
  };
}) {
  return (
    <TableContainer>
      <Table size="medium" aria-label="simple table">
        <TableHead sx={{ backgroundColor: '#E84D1D' }}>
          <TableRow>
            {tableData.headers.map((header) => {
              return (
                <TableCell key={header} sx={{ fontWeight: 'bold' }}>
                  {header}
                </TableCell>
              );
            })}
          </TableRow>
        </TableHead>
        <TableBody sx={{ backgroundColor: '#fff' }}>
          {tableData.rows.map((row: any[], index: number) => (
            <TableRow
              key={index}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              {row.map((rowData: any, index: number) => {
                return (
                  <TableCell align="left" key={index}>
                    {rowData}
                  </TableCell>
                );
              })}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
