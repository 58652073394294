import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

export function ScreenLoadingOrganism({ isOpen }: { isOpen: any }) {
  return (
    <div className='w-full h-full absolute top-0 left-0'>
      <Backdrop open={isOpen}>
        <CircularProgress color='inherit' />
      </Backdrop>
    </div>
  );
}
