import { faLessThan } from '@fortawesome/free-solid-svg-icons';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  AudienceStatusEnum,
  ChangeAudienceStatusFormValues,
} from '../../../../entities/requests/audience.entity';
import { RootState } from '../../../store';

export interface AudienceState {
  changeAudienceStatusFormValues: ChangeAudienceStatusFormValues;
}

const initialState: AudienceState = {
  changeAudienceStatusFormValues: {
    _id: '',
    status: AudienceStatusEnum.PENDING,
    date: null,
    presentationTimeLimit: null,
    protestTimeLimitExtended: false,
    protestFiledInTime: false,
    requesterRepresentedBy: null,
    protestees: null,
    witnesses: null,
    interpreters: null,

    // Validity
    validityObjectionChecked: false,
    validityObjectionObservation: null,
    validityTextRepresentsIncidentChecked: false,
    validityTextRepresentsIncidentObservation: null,
    validityHailedOutLoudChecked: false,
    validityHailedOutLoudObservation: null,
    validityNotifiedAtFirstOportunityChecked: false,
    validityNotifiedAtFirstOportunityObservation: null,
    validityRedFlagDisplayedChecked: false,
    validityRedFlagDisplayedObservation: null,
    validityRedFlagSeenByRaceCommiteeChecked: false,
    validityRedFlagSeenByRaceCommiteeObservation: null,
    validityIsAudienceValid: false,
    validityIsAudienceInvalid: false,

    proceduralMatters: null,
    provedFacts: null,
    largeConclusion: null,

    longResolution: null,
    shortResolution: null,

    juryMembers: null,

    observations: null,
  },
};

export const audiencesSlice = createSlice({
  name: 'audiences',
  initialState,
  reducers: {
    changeFormValue: (state, action: PayloadAction<{ key: any; value: any }>) => {
      return {
        ...state,
        changeAudienceStatusFormValues: {
          ...state.changeAudienceStatusFormValues,
          [action.payload.key]: action.payload.value,
        },
      };
    },
    resetValuesToInitState: (state, action: PayloadAction<undefined>) => {
      return initialState;
    },
  },
});

// Action creators are generated for each case reducer function
export const { changeFormValue, resetValuesToInitState } = audiencesSlice.actions;

export default audiencesSlice.reducer;

export const selectAllFormData = (state: RootState) =>
  state.audiences.changeAudienceStatusFormValues;
