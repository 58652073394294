import {
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from '@mui/material';
import CompetitorPartial from './CompetitorPartial';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';

import { useSelector, useDispatch } from 'react-redux';
import {
  selectAllNewRequestFormValues,
  changeNewRequestFormValues,
  Competitor,
} from '../../../../redux/features/requests/requestsSlice';

import Button from '@mui/material/Button';

import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

export default function NewAudiencePartial() {
  const dispatch = useDispatch();
  const newAudienceFormValues = useSelector(selectAllNewRequestFormValues);

  const handlers = {
    handleAudienceTypeOnChange: (e: SelectChangeEvent) => {
      const newValue = e.target.value as string;

      dispatch(
        changeNewRequestFormValues({
          key: 'audienceType',
          value: newValue,
        }),
      );
    },
    handleProtesteeListCategoryChange: (index: number, value: string) => {
      const tempCompList: Competitor[] = [...newAudienceFormValues.protestees];
      tempCompList[index] = {
        ...tempCompList[index],
        category: value,
      };

      dispatch(
        changeNewRequestFormValues({
          key: 'protestees',
          value: tempCompList,
        }),
      );
    },
    handleProtesteeListSailNumberChange: (index: number, value: string) => {
      const tempCompList: Competitor[] = [...newAudienceFormValues.protestees];
      tempCompList[index] = {
        ...tempCompList[index],
        sailNumber: value,
      };

      dispatch(
        changeNewRequestFormValues({
          key: 'protestees',
          value: tempCompList,
        }),
      );
    },
    handleProtesteeListDeleteItem: (index: number) => {
      const tempCompList = [...newAudienceFormValues.protestees];
      tempCompList.splice(index, 1);

      dispatch(
        changeNewRequestFormValues({
          key: 'protestees',
          value: tempCompList,
        }),
      );
    },
    handleAddProtesteeOnClick: () => {
      const tempCompList = [...newAudienceFormValues.protestees];
      tempCompList.push({ category: null, sailNumber: '' });

      dispatch(
        changeNewRequestFormValues({
          key: 'protestees',
          value: tempCompList,
        }),
      );
    },
    handleRequesterBoatCategoryChange: (newValue: string) => {
      const tempRequester = { ...newAudienceFormValues.requesterBoat };
      tempRequester.category = newValue;

      dispatch(
        changeNewRequestFormValues({
          key: 'requesterBoat',
          value: tempRequester,
        }),
      );
    },
    handleRequesterBoatSailNumberChange: (newValue: string) => {
      const tempRequester = { ...newAudienceFormValues.requesterBoat };
      tempRequester.sailNumber = newValue;

      dispatch(
        changeNewRequestFormValues({
          key: 'requesterBoat',
          value: tempRequester,
        }),
      );
    },
    handleIncidentWitnessesListCategoryChange: (index: number, value: string) => {
      const tempCompList: Competitor[] = [...newAudienceFormValues.incidentWitnesses];
      tempCompList[index] = {
        ...tempCompList[index],
        category: value,
      };

      dispatch(
        changeNewRequestFormValues({
          key: 'incidentWitnesses',
          value: tempCompList,
        }),
      );
    },
    handleIncidentWitnessesListSailNumberChange: (index: number, value: string) => {
      const tempCompList: Competitor[] = [...newAudienceFormValues.incidentWitnesses];
      tempCompList[index] = {
        ...tempCompList[index],
        sailNumber: value,
      };

      dispatch(
        changeNewRequestFormValues({
          key: 'incidentWitnesses',
          value: tempCompList,
        }),
      );
    },
    handleIncidentWitnessesListDeleteItem: (index: number) => {
      const tempCompList = [...newAudienceFormValues.incidentWitnesses];
      tempCompList.splice(index, 1);

      dispatch(
        changeNewRequestFormValues({
          key: 'incidentWitnesses',
          value: tempCompList,
        }),
      );
    },
    handleAddIncidentWitnessesOnClick: () => {
      const tempCompList = [...newAudienceFormValues.incidentWitnesses];
      tempCompList.push({ category: null, sailNumber: '' });

      dispatch(
        changeNewRequestFormValues({
          key: 'incidentWitnesses',
          value: tempCompList,
        }),
      );
    },
  };

  return (
    <>
      {/* Evento */}
      <br />
      <br />
      <Typography sx={{ fontWeight: 'bold' }}>Evento</Typography>
      <TextField
        sx={{ backgroundColor: 'lightgrey', color: 'black' }}
        disabled
        value={'2023 Rio de la Plata Vela Fest'}
        fullWidth
      />
      <br />
      <br />
      <MobileDatePicker
        label='Dia y hora de la regata'
        className='w-full'
        value={
          newAudienceFormValues.eventRaceDate ? new Date(newAudienceFormValues.eventRaceDate) : null
        }
        onChange={(newValue: Date | null) => {
          dispatch(
            changeNewRequestFormValues({
              key: 'eventRaceDate',
              value: newValue?.toString(),
            }),
          );
        }}
        renderInput={(params) => <TextField {...params} />}
        inputFormat='dd/MM/yyyy'
        mask='__/__/____'
      />
      <br />
      <br />
      <TextField
        label='Regata N°'
        value={newAudienceFormValues.eventRaceNumber}
        onChange={(e) => {
          dispatch(
            changeNewRequestFormValues({
              key: 'eventRaceNumber',
              value: e.target.value as string,
            }),
          );
        }}
        fullWidth
      />
      {/* Tipo de audiencia */}
      <br />
      <br />
      <Typography sx={{ fontWeight: 'bold' }}>Tipo de audiencia</Typography>
      <FormControl fullWidth margin='normal'>
        <InputLabel id='audiencia-label'>Tipo de Audiencia</InputLabel>
        <Select
          name=''
          labelId='audiencia-label'
          id='audiencia-select'
          label='Tipo de Audiencia'
          variant='outlined'
          value={newAudienceFormValues.audienceType}
          onChange={handlers.handleAudienceTypeOnChange}
          required
        >
          <MenuItem value={'protest-b2b'}>Protesta de un barco a otro</MenuItem>
          <MenuItem value={'protest-rc2b'}>Protesta de comision de regata a un barco</MenuItem>
          <MenuItem value={'protest-pc2b'}>Protesta de comision de protesta a un barco</MenuItem>
          <MenuItem value={'redress-by-boat-or-rc'}>
            Pedido de reparacion por barco o comision de regata
          </MenuItem>
          <MenuItem value={'redress-by-pc'}>
            Consideracion de reparacion por com. de protesta
          </MenuItem>
          <MenuItem value={'reopen-by-boat'}>
            Pedido de reapertura por barco o comision de regata
          </MenuItem>
          <MenuItem value={'reopen-by-pc'}>
            Consideracion de reapertura por com. de protesta
          </MenuItem>
        </Select>
      </FormControl>
      {/* Barco que protesta */}
      <br />
      <br />
      <Typography sx={{ fontWeight: 'bold', wordBreak: 'break-word' }}>
        {'Barco que protesta, pide reparacion o reapertura'}
      </Typography>
      <CompetitorPartial
        categoryValue={newAudienceFormValues.requesterBoat.category}
        handleCategoryChange={handlers.handleRequesterBoatCategoryChange}
        sailNumberValue={newAudienceFormValues.requesterBoat.sailNumber}
        handleSailNumberChange={handlers.handleRequesterBoatSailNumberChange}
        isAudience={true}
      />
      {/* Barcos protestados */}
      <br />
      <br />
      <Typography sx={{ fontWeight: 'bold', wordBreak: 'break-word' }}>
        {'Barco(s) protestado(s) o considerado(s) para una reparacion'}
      </Typography>
      {newAudienceFormValues.protestees.map((competitor: Competitor, index: number) => (
        <div key={index}>
          <CompetitorPartial
            categoryValue={newAudienceFormValues.protestees[index].category}
            handleCategoryChange={handlers.handleProtesteeListCategoryChange}
            sailNumberValue={newAudienceFormValues.protestees[index].sailNumber}
            handleSailNumberChange={handlers.handleProtesteeListSailNumberChange}
            enableDeleteLine={newAudienceFormValues.protestees.length !== 1}
            handleDeleteLine={handlers.handleProtesteeListDeleteItem}
            index={index}
            isAudience={true}
          />
          <br />
        </div>
      ))}
      <Button
        variant='outlined'
        startIcon={<AddCircleOutlineIcon />}
        fullWidth
        onClick={() => {
          handlers.handleAddProtesteeOnClick();
        }}
      >
        Añadir otro barco
      </Button>
      {/* Incidente */}
      <br />
      <br />
      <Typography sx={{ fontWeight: 'bold', wordBreak: 'break-word' }}>{'Incidente'}</Typography>
      <TextField
        label='Hora y lugar del incidente'
        value={newAudienceFormValues.incidentTime}
        onChange={(e) => {
          dispatch(
            changeNewRequestFormValues({
              key: 'incidentTime',
              value: e.target.value as string,
            }),
          );
        }}
        fullWidth
      />
      <br />
      <br />
      <TextField
        label='Reglas que se habrian infringido'
        value={newAudienceFormValues.incidentRulesInringed}
        onChange={(e) => {
          dispatch(
            changeNewRequestFormValues({
              key: 'incidentRulesInringed',
              value: e.target.value as string,
            }),
          );
        }}
        fullWidth
      />
      <br />
      <br />
      <Typography sx={{ fontWeight: 'normal', wordBreak: 'break-word' }}>{'Testigos'}</Typography>
      {newAudienceFormValues.incidentWitnesses.map((competitor: Competitor, index: number) => (
        <div key={index}>
          <CompetitorPartial
            categoryValue={newAudienceFormValues.incidentWitnesses[index].category}
            handleCategoryChange={handlers.handleIncidentWitnessesListCategoryChange}
            sailNumberValue={newAudienceFormValues.incidentWitnesses[index].sailNumber}
            handleSailNumberChange={handlers.handleIncidentWitnessesListSailNumberChange}
            enableDeleteLine={true}
            handleDeleteLine={handlers.handleIncidentWitnessesListDeleteItem}
            index={index}
            isAudience={true}
          />
          <br />
        </div>
      ))}
      <Button
        variant='outlined'
        startIcon={<AddCircleOutlineIcon />}
        fullWidth
        onClick={() => {
          handlers.handleAddIncidentWitnessesOnClick();
        }}
      >
        Añadir testigo
      </Button>

      {/* Aviso al protestado */}
      <br />
      <br />
      <Typography sx={{ fontWeight: 'bold', wordBreak: 'break-word' }}>
        {'Aviso al protestado'}
      </Typography>
      <FormControlLabel
        control={
          <Checkbox
            onChange={(e) => {
              dispatch(
                changeNewRequestFormValues({
                  key: 'hailHailedOutloud',
                  value: e.target.checked || false,
                }),
              );
            }}
            checked={newAudienceFormValues.hailHailedOutloud}
          />
        }
        label='Se aviso a viva voz'
      />
      <TextField
        label='Cuando?'
        value={newAudienceFormValues.hailSituation}
        onChange={(e) => {
          dispatch(
            changeNewRequestFormValues({
              key: 'hailSituation',
              value: e.target.value as string,
            }),
          );
        }}
        fullWidth
      />
      <TextField
        label='Palabra(s) usada(s)'
        value={newAudienceFormValues.hailWordsUsed}
        onChange={(e) => {
          dispatch(
            changeNewRequestFormValues({
              key: 'hailWordsUsed',
              value: e.target.value as string,
            }),
          );
        }}
        fullWidth
      />
      <br />
      <br />
      <FormControlLabel
        control={
          <Checkbox
            onChange={(e) => {
              dispatch(
                changeNewRequestFormValues({
                  key: 'redFlag',
                  value: e.target.checked || false,
                }),
              );
            }}
            checked={newAudienceFormValues.redFlag}
          />
        }
        label='Se desplego una bandera roja'
      />
      <TextField
        label='Cuando?'
        value={newAudienceFormValues.redFlagSituation}
        onChange={(e) => {
          dispatch(
            changeNewRequestFormValues({
              key: 'redFlagSituation',
              value: e.target.value as string,
            }),
          );
        }}
        fullWidth
      />
      <br />
      <br />
      <FormControlLabel
        control={
          <Checkbox
            onChange={(e) => {
              dispatch(
                changeNewRequestFormValues({
                  key: 'informedOtherWay',
                  value: e.target.checked || false,
                }),
              );
            }}
            checked={newAudienceFormValues.informedOtherWay}
          />
        }
        label='Se informo de otro modo'
      />
      <TextField
        label='Aclare como'
        value={newAudienceFormValues.informedOtherWayWordsUsed}
        onChange={(e) => {
          dispatch(
            changeNewRequestFormValues({
              key: 'informedOtherWayWordsUsed',
              value: e.target.value as string,
            }),
          );
        }}
        fullWidth
      />

      {/* Descripcion del incidente */}
      <br />
      <br />
      <Typography sx={{ fontWeight: 'bold', wordBreak: 'break-word' }}>
        {'Descripcion del incidente'}
      </Typography>
      <TextField
        label='Descripcion del incidente'
        value={newAudienceFormValues.incidentDescription}
        onChange={(e) => {
          dispatch(
            changeNewRequestFormValues({
              key: 'incidentDescription',
              value: e.target.value as string,
            }),
          );
        }}
        fullWidth
        multiline
        rows={4}
      />
    </>
  );
}
