import { EquipmentChange } from '../../../entities/requests/equipmentChange.entity';
import { useGetAllEquipmentChangeRequestsQuery } from '../../../redux/service/velafestApi';
import { ScreenLoadingOrganism } from '../../UI/organisms/ScreenLoadingOrganism';
import EquipmentChangeAccordion from './EquipmentChangeAccordion';

export default function EquipmentChangeList() {
  const {
    data: equipmentChangeList,
    isError: equipmentChangeIsError,
    error: equipmentChangeError,
    isLoading: equipmentChangeIsLoading,
  } = useGetAllEquipmentChangeRequestsQuery('');

  if (equipmentChangeIsLoading) return <ScreenLoadingOrganism isOpen={equipmentChangeIsLoading} />;

  if (equipmentChangeIsError) console.error(equipmentChangeError);

  return (
    <div className='flex flex-col justify-center p-8 md:w-3/4 lg:w-1/2 w-full'>
      {equipmentChangeList.data
        .map((equipmentChange: EquipmentChange, index: number) => {
          return <EquipmentChangeAccordion equipmentChange={equipmentChange} key={index} />;
        })
        .sort((a: EquipmentChange, b: EquipmentChange) => {
          return (b.equipmentChangeId || 0) - (a.equipmentChangeId || 1);
        })}
    </div>
  );
}
