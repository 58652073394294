import { TextField, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import {
  changeNewRequestFormValues,
  selectAllNewRequestFormValues,
} from '../../../../redux/features/requests/requestsSlice';
import CompetitorPartial from './CompetitorPartial';

export default function NewResultsReviewPartial() {
  const newResultsReviewFormValues = useSelector(selectAllNewRequestFormValues);
  const dispatch = useDispatch();

  const handlers = {
    handleRequesterBoatCategoryChange: (newValue: string) => {
      const tempRequester = { ...newResultsReviewFormValues.requesterBoat };
      tempRequester.category = newValue;

      dispatch(
        changeNewRequestFormValues({
          key: 'requesterBoat',
          value: tempRequester,
        }),
      );
    },
    handleRequesterBoatSailNumberChange: (newValue: string) => {
      const tempRequester = { ...newResultsReviewFormValues.requesterBoat };
      tempRequester.sailNumber = newValue;

      dispatch(
        changeNewRequestFormValues({
          key: 'requesterBoat',
          value: tempRequester,
        }),
      );
    },
  };

  return (
    <>
      {/* Barco que pide la revision */}
      <br />
      <br />
      <Typography sx={{ fontWeight: 'bold', wordBreak: 'break-word' }}>
        {'Barco que pide revision de resultados'}
      </Typography>
      <CompetitorPartial
        categoryValue={newResultsReviewFormValues.requesterBoat.category}
        handleCategoryChange={handlers.handleRequesterBoatCategoryChange}
        sailNumberValue={newResultsReviewFormValues.requesterBoat.sailNumber}
        handleSailNumberChange={handlers.handleRequesterBoatSailNumberChange}
        isAudience={false}
      />
      <br />
      <Typography sx={{ fontWeight: 'bold', wordBreak: 'break-word' }}>
        {'Regata para la cual se solicita la revision'}
      </Typography>
      <TextField
        label='Regata N°'
        value={newResultsReviewFormValues.eventRaceNumber}
        onChange={(e) => {
          dispatch(
            changeNewRequestFormValues({
              key: 'eventRaceNumber',
              value: e.target.value as string,
            }),
          );
        }}
        fullWidth
        required
      />
      <br />
      <br />
      <Typography sx={{ fontWeight: 'bold', wordBreak: 'break-word' }}>
        {'Resultado Actual'}
      </Typography>
      <TextField
        label='Resultado Actual'
        value={newResultsReviewFormValues.actualResult}
        onChange={(e) => {
          dispatch(
            changeNewRequestFormValues({
              key: 'actualResult',
              value: e.target.value as string,
            }),
          );
        }}
        fullWidth
        required
      />
      <br />
      <br />
      <Typography sx={{ fontWeight: 'bold', wordBreak: 'break-word' }}>
        {'Resultado Solicitado'}
      </Typography>
      <TextField
        label='Resultado Solicitado'
        value={newResultsReviewFormValues.requestedResult}
        onChange={(e) => {
          dispatch(
            changeNewRequestFormValues({
              key: 'requestedResult',
              value: e.target.value as string,
            }),
          );
        }}
        fullWidth
        required
      />
      <br />
      <br />
      <Typography sx={{ fontWeight: 'bold', wordBreak: 'break-word' }}>
        {'Observaciones'}
      </Typography>
      <TextField
        label='Observaciones'
        value={newResultsReviewFormValues.request}
        onChange={(e) => {
          dispatch(
            changeNewRequestFormValues({
              key: 'request',
              value: e.target.value as string,
            }),
          );
        }}
        multiline
        rows={4}
        fullWidth
      />
    </>
  );
}
