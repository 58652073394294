export const getBeBaseUrl = (): string => {
  console.log(`window.location.hostname: ${window.location.hostname}`);

  if (window.location.hostname === 'velafest.com.ar') {
    return 'https://api.velafest.com.ar';
  }

  if (window.location.hostname === '2023.velafest.com.ar') {
    return 'https://api-2023.velafest.com.ar';
  }

  if (window.location.hostname === 'uat.velafest.com.ar') {
    return 'https://api.uat.velafest.com.ar';
  }

  if (window.location.hostname === '89.117.33.148') {
    return 'http://89.117.33.148:8081';
  }

  return 'http://localhost:3001';
  // return 'https://api.velafest.com.ar';
};

interface IConfig {
  urls: {
    modules: {
      documents: string;
      authentication: string;
      results: string;
      requests: string;
      registrations: string;
    };
  };
}

export const Config: IConfig = {
  urls: {
    modules: {
      documents: `${getBeBaseUrl()}/documents`,
      authentication: `${getBeBaseUrl()}/auth`,
      results: `${getBeBaseUrl()}/results`,
      requests: `${getBeBaseUrl()}/requests`,
      registrations: `${getBeBaseUrl()}/registrations`,
    },
  },
};
