import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

export default function NoPage() {
  return (
    <div className='grid place-items-center w-full h-full m-0 p-0'>
      <Helmet>
        <title>No Encontrado | Campeonato Velafest</title>
        <meta
          name='description'
          content='No se ha encontrado la pagina que buscas. El campeonato Velafest es un campeonato que se realiza en el rio de la plata, organizado en conjunto ente el Club de Veleros Barlovento y el Club Náutico Albatros.'
        />
        <meta name='robots' content='noindex' />
      </Helmet>
      <h1 className='text-4xl text-center md:text-6xl'>No se encontró la pagina que buscas</h1>
      <h4>
        <Link to='/' className='text-2xl text-vfblue my-4'>
          Volver al inicio
        </Link>
      </h4>
    </div>
  );
}
