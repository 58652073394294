import { useMemo } from 'react';
import { useCookies } from 'react-cookie';
import { Helmet } from 'react-helmet';
import { Navigate, useLocation } from 'react-router-dom';

export default function LogoutPage() {
  const [, , removeCookie] = useCookies(['jwt']);

  removeCookie('jwt', { path: '/' });

  function useQuery() {
    const { search } = useLocation();

    return useMemo(() => new URLSearchParams(search), [search]);
  }

  const queryParams = useQuery();
  const errorMessage = queryParams.get('errorMessage');
  const redirectURI = queryParams.get('redirectURI');

  return (
    <div>
      <Helmet>
        <title>Logout Admin | Campeonato Velafest</title>
        <meta
          name='description'
          content='Cerrar sesión para administradores del Campeonato Velafest (sección solo para administradores). El campeonato Velafest es un campeonato que se realiza en el rio de la plata, organizado en conjunto ente el Club de Veleros Barlovento y el Club Náutico Albatros.'
        />
        <meta name='robots' content='noindex' />
      </Helmet>
      {!errorMessage && <Navigate to={`/vf-admin/login?redirectURI=${redirectURI ?? '/'}`} />}
      {errorMessage && (
        <Navigate
          to={`/vf-admin/login?errorMessage=${errorMessage}&redirectURI=${redirectURI ?? '/'}`}
        />
      )}
    </div>
  );
}
