import { faLink } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DocumentsTableOrganism from '../UI/organisms/DocumentsTableOrganism';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useCookies } from 'react-cookie';
import { showAlert } from '../../utils/showAlert';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { useState } from 'react';
import { Helmet } from 'react-helmet';
import Paper from '@mui/material/Paper';
import { Config } from '../../lib/vf-sdk/config';
import { Divider, IconButton } from '@mui/material';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import CancelIcon from '@mui/icons-material/Cancel';

const newDocumentDefaultValues = {
  title: '',
  url: '',
  file: undefined,
}

export default function DocumentsTemplate({
  documents,
  setDocuments,
  isAdmin,
  htmlHead,
}: {
  documents: any[];
  setDocuments: any;
  isAdmin: boolean;
  htmlHead: {
    title: string;
    description: string;
    shouldIndex: boolean;
  };
}) {
  const [cookies, , removeCookie] = useCookies(['jwt']);

  const [newDocumentDialogIsOpen, setNewDocumentDialogIsOpen] = useState<boolean>(false);
  const [newDocumentFormValues, setNewDocumentFormValues] = useState<{
    title: string;
    url: string;
    file: any;
  }>(newDocumentDefaultValues);

  const [deleteDocumentDialogIsOpen, setDeleteDocumentDialogIsOpen] = useState<boolean>(false);
  const [deleteDocumentId, setDeleteDocumentId] = useState<number>();

  const deleteDocumentDialogHandleAccept = (e: any) => {
    e.preventDefault();

    fetch(Config.urls.modules.documents + '/' + deleteDocumentId, {
      mode: 'cors',
      method: 'DELETE',
      headers: {
        Authorization: 'Bearer ' + cookies.jwt,
      },
    })
      .then((res) => {
        return res.json();
      })
      .then((res) => {
        if (res.statusCode) {
          showAlert({
            type: 'danger',
            message: res.statusCode,
          });
          return;
        }

        setDeleteDocumentDialogIsOpen(false);
        setDocuments(documents.filter((doc) => doc.id !== deleteDocumentId));

        showAlert({
          type: 'success',
          message: `Se ha eliminado el documento ${deleteDocumentId} satisfactoriamente`,
        });
      });
  };

  const handleDeleteDocumentClick = (e: any) => {
    setDeleteDocumentDialogIsOpen(true);
  };

  const handleDeleteDocumentDialogOnClose = (e: any) => {
    setDeleteDocumentDialogIsOpen(false);
  };

  const newDocumentFormHandleInputChange = (e: any) => {
    const { name, value } = e.target;
    setNewDocumentFormValues({
      ...newDocumentFormValues,
      [name]: value,
    });
  };

  const newDocumentFormHandleSubmit = async (e: any) => {
    e.preventDefault();

    const data = {
      name: newDocumentFormValues.title,
      url: newDocumentFormValues.url,
      file: newDocumentFormValues.file
    };

    if (data.file !== undefined) {
      showAlert({
        type: 'info',
        message: 'Subiendo archivo. Espere porfavor...'
      })

      const formData = new FormData();
      formData.append('name', data.name);
      formData.append('file', data.file);

      await fetch(Config.urls.modules.documents + '/upload', {
        mode: 'cors',
        method: 'POST',
        headers: {
          Authorization: 'Bearer ' + cookies.jwt,
        },
        body: formData
      }).then((res: Response) => {
        if (res.status === 201) {
          setNewDocumentDialogIsOpen(false);

          showAlert({
            type: 'success',
            message: 'Se ha creado el documento con exito',
          });

          window.location.reload();
        } else if (res.status === 401) {
          //
          removeCookie('jwt', { path: '/' });
          window.location.href =
            '/vf-admin/logout?errorMessage=Sesion%20vencida.%20Vuelva%20a%20iniciarla';
        } else {
          showAlert({
            type: 'danger',
            message: `Ha fallado la creacion del documento. Codigo: ${res.status} ${res.statusText}`,
          });
          throw new Error('Status code is not 201 when creating document');
        }
      });
    } else {
      await fetch(Config.urls.modules.documents, {
        mode: 'cors',
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + cookies.jwt,
        },
        body: JSON.stringify(data),
      }).then((res: Response) => {
        if (res.status === 201) {
          setNewDocumentDialogIsOpen(false);

          showAlert({
            type: 'success',
            message: 'Se ha creado el documento con exito',
          });

          window.location.reload();
        } else if (res.status === 401) {
          //
          removeCookie('jwt', { path: '/' });
          window.location.href =
            '/vf-admin/logout?errorMessage=Sesion%20vencida.%20Vuelva%20a%20iniciarla';
        } else {
          showAlert({
            type: 'danger',
            message: `Ha fallado la creacion del documento. Codigo: ${res.status} ${res.statusText}`,
          });
          throw new Error('Status code is not 201 when creating document');
        }
      });

      setNewDocumentFormValues(newDocumentDefaultValues);
    }
  };

  const handleNewDocumentClick = (e: any) => {
    setNewDocumentDialogIsOpen(true);
  };

  const handleNewDocumentDialogOnClose = (e: any) => {
    setNewDocumentDialogIsOpen(false);
  };

  const handleDeleteClick = (e: any, id: number) => {
    setDeleteDocumentId(id);
    setDeleteDocumentDialogIsOpen(true);
  };

  const docsHeaders: any[] = ['Fecha de Publicacion', 'Documento'];

  if (isAdmin) {
    docsHeaders.push('Eliminar');
  }

  const docsRows: any[][] = documents.map((document, index: number) => {
    const row: any[] = [
      <p style={{ fontSize: '1rem' }} key={index}>
        {document.publishDate}
      </p>,
      <a
        href={document.document.url}
        style={{ color: 'blue', fontSize: '1rem', textDecoration: 'none' }}
        target='_blank'
        rel='noreferrer'
        key={index}
      >
        <FontAwesomeIcon icon={faLink} style={{ color: 'black' }} />
        &nbsp;
        {document.document.title}
      </a>,
    ];

    if (isAdmin) {
      row.push(
        <Button
          variant='contained'
          color='error'
          onClick={(e: any) => {
            handleDeleteClick(e, document._id);
          }}
        >
          Eliminar
        </Button>,
      );
    }

    return row;
  });
  const tableData: {
    headers: any[];
    rows: any[][];
  } = {
    headers: docsHeaders,
    rows: docsRows,
  };

  const renderTable = () => {
    if (isAdmin) {
      return true;
    }

    if (documents.length > 0) {
      return true;
    }

    return false;
  };

  return (
    <div className='flex flex-col items-center w-full h-full'>
      <Helmet>
        <title>{htmlHead.title}</title>
        <meta name='description' content={htmlHead.description} />
        {!htmlHead.shouldIndex && <meta name='robots' content='noindex' />}
      </Helmet>
      {renderTable() && (
        <div className='flex flex-col items-center h-full w-full p-4'>
          <h1 className='text-6xl font-bold text-vfblue'>Documentos</h1>
          <div className='p-6 md:w-3/4'>
            <div className='py-2'>
              {isAdmin && (
                <Button
                  variant='outlined'
                  startIcon={<AddCircleOutlineIcon />}
                  onClick={handleNewDocumentClick}
                >
                  Nuevo Documento
                </Button>
              )}
            </div>
            <div className='w-full'>
              <DocumentsTableOrganism tableData={tableData} />
            </div>
          </div>
        </div>
      )}
      {!renderTable() && (
        <div className='flex justify-center items-center w-full h-full'>
          <Paper elevation={2}>
            <h1 className='p-6 text-2xl'>Todavia no hay documentos cargados</h1>
          </Paper>
        </div>
      )}
      {/* New Document Form Dialog */}
      <Dialog open={newDocumentDialogIsOpen} onClose={handleNewDocumentDialogOnClose}>
        <form onSubmit={newDocumentFormHandleSubmit}>
          <DialogTitle>Nuevo Documento</DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              margin='normal'
              name='title'
              id='documentTitle'
              label='Titulo del Documento'
              type='text'
              variant='outlined'
              onChange={newDocumentFormHandleInputChange}
              required
            />
            <br />
            <TextField
              margin='normal'
              name='url'
              id='documentUrl'
              label='URL al Documento'
              type='url'
              variant='outlined'
              onChange={newDocumentFormHandleInputChange}
              disabled={newDocumentFormValues.file !== undefined}
            />
            <br />
            <Divider textAlign='center'>o</Divider>
          <Button
            variant='outlined'
            component='label'
            sx={{ display: 'flex', justifyContent: 'space-evenly', marginTop: '5%' }}
            fullWidth
            disabled={newDocumentFormValues.url !== ''}
          >
            <FileUploadIcon />
            Subir Archivo
            <input
              type='file'
              name='file'
              id='documentFile'
              hidden
              onChange={(e: any) => {
                setNewDocumentFormValues({
                  ...newDocumentFormValues,
                  file: e.currentTarget.files[0]
                })
              }}
            />
          </Button>
          {newDocumentFormValues.file && (
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'start', alignItems: 'center', paddingTop: '3%', maxWidth: '231.64px' }} >
              <IconButton
                onClick={() => {
                  setNewDocumentFormValues({
                    ...newDocumentFormValues,
                    file: undefined
                  })
                }}
              >
                <CancelIcon />
              </IconButton>
              <p style={{ marginLeft: '2%', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                {newDocumentFormValues.file.name}
              </p>
            </div>
          )}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleNewDocumentDialogOnClose}>Cancelar</Button>
            <Button type='submit'>Guardar</Button>
          </DialogActions>
        </form>
      </Dialog>
      {/* Remove Document Consent Dialog */}
      <Dialog open={deleteDocumentDialogIsOpen} onClose={handleDeleteDocumentDialogOnClose}>
        <DialogTitle>Estas seguro?</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {`Estas seguro de que quieres eliminar el documento: ${
              documents.filter((document) => document.id === deleteDocumentId)[0]?.document.title
            }`}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteDocumentDialogOnClose}>Cancelar</Button>
          <Button color='error' variant='contained' onClick={deleteDocumentDialogHandleAccept}>
            Eliminar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
