import ScheduleTemplate from '../templates/ScheduleTemplate';

export default function SchedulePage() {
  const events: {
    date: string;
    time: string;
    description: string;
    boldText: boolean;
  }[] = [
    {
      date: '28/02/2023',
      time: '16:00hs',
      description: 'Cierre de inscripcion sin recargo 420, 29er y Snipe',
      boldText: false,
    },
    {
      date: '04/03/2023',
      time: '16:00hs',
      description: 'Cierre de inscripcion sin recargo F-18, Finn, Cadet y Lightning',
      boldText: false,
    },
    {
      date: '10/03/2023',
      time: '16:00hs',
      description: 'Cierre de inscripcion 420, 29er y Snipe',
      boldText: false,
    },
    {
      date: '11/03/2023',
      time: '13:00hs',
      description: 'Primera señal de atencion 420, 29er y Snipe',
      boldText: true,
    },
    {
      date: '12/03/2023',
      time: '12:00hs',
      description: 'Primera señal de atencion 420, 29er y Snipe',
      boldText: true,
    },
    {
      date: '17/03/2023',
      time: '16:00hs',
      description: 'Cierre de inscripcion F-18, Finn, Cadet y Lightning',
      boldText: false,
    },
    {
      date: '17/03/2023',
      time: '16:00hs',
      description:
        'Cierre de inscripcion sin recargo Optimist Timoneles, Optimist Principiantes, ILCA 7, ILCA 6 e ILCA 4',
      boldText: false,
    },
    {
      date: '18/03/2023',
      time: '13:00hs',
      description: 'Primera señal de atencion F-18, Finn, Cadet y Lightning',
      boldText: true,
    },
    {
      date: '19/03/2023',
      time: '12:00hs',
      description: 'Primera señal de atencion F-18, Finn, Cadet y Lightning',
      boldText: true,
    },
    {
      date: '23/03/2023',
      time: '16:00hs',
      description:
        'Cierre de inscripcion Optimist Timoneles, Optimist Principiantes, ILCA 7, ILCA 6 e ILCA 4',
      boldText: false,
    },
    {
      date: '25/03/2023',
      time: '13:00hs',
      description:
        'Primera señal de atencion Optimist Timoneles, Optimist Principiantes, ILCA 7, ILCA 6 e ILCA 4',
      boldText: true,
    },
    {
      date: '26/03/2023',
      time: '12:00hs',
      description:
        'Primera señal de atencion Optimist Timoneles, Optimist Principiantes, ILCA 7, ILCA 6 e ILCA 4',
      boldText: true,
    },
  ];

  const htmlHead: {
    title: string;
    description: string;
    shouldIndex: boolean;
  } = {
    title: 'Programa | Campeonato Velafest',
    description:
      'Programa del Campeonato Velafest. El campeonato Velafest es un campeonato que se realiza en el rio de la plata, organizado en conjunto ente el Club de Veleros Barlovento y el Club Nautico Albatros.',
    shouldIndex: true,
  };

  return <ScheduleTemplate events={events} htmlHead={htmlHead} />;
}
