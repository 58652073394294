import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../store';

export interface Competitor {
  category: string | null;
  sailNumber: string | null;
}

export interface RequestState {
  newRequestformValues: {
    _id: string;
    requestType: string;

    eventRaceDate: string | null;
    eventRaceNumber: string | null;

    audienceType: string;

    requesterBoat: Competitor;
    protestees: Competitor[];

    incidentTime: string | null;
    incidentRulesInringed: string | null;
    incidentWitnesses: Competitor[];

    hailHailedOutloud: boolean;
    hailSituation: string | null;
    hailWordsUsed: string | null;
    redFlag: boolean;
    redFlagSituation: string | null;
    informedOtherWay: boolean;
    informedOtherWayWordsUsed: string | null;

    incidentDescription: string | null;

    /* ResultsReview only data */
    actualResult: string;
    requestedResult: string;
    request: string;
    resultsReviewStatus: string;
    requestResolution: string;

    equipmentChangeStatus: string;
  };
}

const initialState: RequestState = {
  newRequestformValues: {
    _id: '',
    requestType: 'AUDIENCE',

    eventRaceDate: null,
    eventRaceNumber: '',

    audienceType: '',

    requesterBoat: {
      category: null,
      sailNumber: '',
    },
    protestees: [
      {
        category: null,
        sailNumber: '',
      },
    ],

    incidentTime: null,
    incidentRulesInringed: null,
    incidentWitnesses: [],

    hailHailedOutloud: false,
    hailSituation: null,
    hailWordsUsed: null,
    redFlag: false,
    redFlagSituation: null,
    informedOtherWay: false,
    informedOtherWayWordsUsed: null,

    incidentDescription: null,

    /* ResultsReview only data */
    actualResult: '',
    requestedResult: '',
    request: '',
    resultsReviewStatus: 'Pendiente',
    requestResolution: '',

    equipmentChangeStatus: 'Pendiente',
  },
};

export const requestsSlice = createSlice({
  name: 'requests',
  initialState,
  reducers: {
    changeNewRequestFormValues: (state, action: PayloadAction<{ key: any; value: any }>) => {
      return {
        ...state,
        newRequestformValues: {
          ...state.newRequestformValues,
          [action.payload.key]: action.payload.value,
        },
      };
    },
    resetValuesToInitState: (state, action: PayloadAction<null>) => {
      return initialState;
    },
  },
});

// Action creators are generated for each case reducer function
export const { changeNewRequestFormValues, resetValuesToInitState } = requestsSlice.actions;

export default requestsSlice.reducer;

export const selectAllNewRequestFormValues = (state: RootState) =>
  state.requests.newRequestformValues;
