import { Helmet } from 'react-helmet';
import TableMolecule from '../UI/molecules/TableMolecule';
import ResultsTableOrganism from '../UI/organisms/ResultsTableOrganism';

export default function ScheduleTemplate({
  events,
  htmlHead
}: {
  events: {
    date: string;
    time: string;
    description: string;
    boldText: boolean;
  }[];
  htmlHead: {
    title: string;
    description: string;
    shouldIndex: boolean;
  };
}) {
  const tableData: {
    headers: any[];
    rows: any[][];
  } = {
    headers: ['Dia', 'Horario', 'Descripcion'],
    rows: events.map((event, index) => {
      if (event.boldText) {
        return [
          <p className="font-bold" key={index}>
            {event.date}
          </p>,
          <p className="font-bold" key={index}>
            {event.time}
          </p>,
          <p className="font-bold" key={index}>
            {event.description}
          </p>
        ];
      }
      return [event.date, event.time, event.description];
    })
  };

  return (
    <div className="flex flex-col justify-center items-center">
      <Helmet>
        <title>{htmlHead.title}</title>
        <meta name="description" content={htmlHead.description} />
        {!htmlHead.shouldIndex && <meta name="robots" content="noindex" />}
      </Helmet>
      <div className="flex flex-col justify-center items-center w-full">
        <h1 className="text-6xl">Programa</h1>
        <div className="p-6 md:w-3/4">
          <div className="w-full">
            <ResultsTableOrganism tableData={tableData} />
          </div>
        </div>
      </div>
    </div>
  );
}
