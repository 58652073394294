import { Paper, Typography } from '@mui/material';

const PreviosEditionsPage = () => {
  return (
    <div className='flex flex-col items-center justify-center w-full min-h-full p-[16px]'>
      <Paper sx={{ p: '16px' }} className='overflow-x-hidden w-full max-w-fit'>
      <Typography variant='h5'>Velafest Orza 2024</Typography>
        <Typography>
          <b>Resultados:</b>
        </Typography>
        <ul
          style={{
            listStyle: 'inside url(/file-pdf-solid.svg)',
            marginLeft: '5%',
          }}
        >
          <li>
            <a
              href='https://drive.google.com/open?id=18qSdBo8QR8lnEShVJXeoo2JtYN9wJRFs'
              rel='noreferrer'
              target='_blank'
            >
              Resultados J70
            </a>
          </li>
        </ul>
        <br />
      <Typography variant='h5'>Velafest Orza 2024</Typography>
        <Typography className='text-ellipsis overflow-hidden whitespace-nowrap'>
          <b>Documentos:</b>{' '}
          <a
            href='https://drive.google.com/drive/folders/1GfVmR6QL6aEW8-PM_pycganBJeYgQ96n?usp=sharing'
            className='text-ellipsis overflow-hidden whitespace-nowrap'
            rel='noreferrer'
            target='_blank'
          >
            https://drive.google.com/drive/folders/1GfVmR6QL6aEW8-PM_pycganBJeYgQ96n?usp=sharing
          </a>
        </Typography>
        <Typography>
          <b>Resultados:</b>
        </Typography>
        <ul
          style={{
            listStyle: 'inside url(/file-pdf-solid.svg)',
            marginLeft: '5%',
          }}
        >
          <li>
            <a
              href='https://drive.google.com/open?id=1DntV4W6qtK3t3Ip6fn9WXapTVJ9SMckA'
              rel='noreferrer'
              target='_blank'
            >
              Resultados Optimist Timoneles
            </a>
          </li>
          <li>
            <a
              href='https://drive.google.com/open?id=10PgaqciPWQoweA9FLROSoDH8jbWbBkz3'
              rel='noreferrer'
              target='_blank'
            >
              Resultados Optimist Principiantes
            </a>
          </li>
          <li>
            <a
              href='https://drive.google.com/open?id=15-GXNCG4YFVr0G4iiL9DbleCaz6QJQ1T'
              rel='noreferrer'
              target='_blank'
            >
              Resultados ILCA 7
            </a>
          </li>
          <li>
            <a
              href='https://drive.google.com/open?id=1Xkgt5v-3GB1ODF-uq299fwsW1FuvXY1s'
              rel='noreferrer'
              target='_blank'
            >
              Resultados ILCA 6
            </a>
          </li>
          <li>
            <a
              href='https://drive.google.com/open?id=1LrUhJ88j5vj15qZhL1LdrbaeaatPsoUc'
              rel='noreferrer'
              target='_blank'
            >
              Resultados ILCA 4
            </a>
          </li>
          <li>
            <a
              href='https://drive.google.com/open?id=15DJb9YFRDfH3jRoL2P-WAVvFW-fUlS0A'
              rel='noreferrer'
              target='_blank'
            >
              Resultados Finn
            </a>
          </li>
          <li>
            <a
              href='https://drive.google.com/open?id=1oq3mnzwU6cE0h95wxE-WDe8rdfyvu0lK'
              rel='noreferrer'
              target='_blank'
            >
              Resultados F18
            </a>
          </li>
          <li>
            <a
              href='https://drive.google.com/open?id=1kPkF3JNWXFCWiRDQ0XBGguuV3-3PB5CC'
              rel='noreferrer'
              target='_blank'
            >
              Resultados Cadet
            </a>
          </li>
          <li>
            <a
              href='https://drive.google.com/open?id=1dl4ZANk0MnyBIu3i156Nz46LLUmWGzkd'
              rel='noreferrer'
              target='_blank'
            >
              Resultados 420
            </a>
          </li>
          <li>
            <a
              href='https://drive.google.com/open?id=1u6JD84QW_vlrc7CRUxH0Co84HTo24rAG'
              rel='noreferrer'
              target='_blank'
            >
              Resultados 29er
            </a>
          </li>
        </ul>
        <br />
        <Typography variant='h5'>Velafest Orza 2023</Typography>
        <Typography className='text-ellipsis overflow-hidden whitespace-nowrap'>
          <b>Documentos:</b>{' '}
          <a
            href='https://drive.google.com/drive/folders/1cljW-pTFP5xnrcRFd4Xq9pXnXcIxwfzq?usp=sharing'
            className='text-ellipsis overflow-hidden whitespace-nowrap'
            rel='noreferrer'
            target='_blank'
          >
            https://drive.google.com/drive/folders/1cljW-pTFP5xnrcRFd4Xq9pXnXcIxwfzq
          </a>
        </Typography>
        <Typography>
          <b>Resultados:</b>
        </Typography>
        <ul
          style={{
            listStyle: 'inside url(/file-pdf-solid.svg)',
            marginLeft: '5%',
          }}
        >
          <li>
            <a
              href='https://drive.google.com/file/d/1xGcvUcrI5ZrhRUo5V97gAVv7Cswp7uK0/view?usp=share_link'
              rel='noreferrer'
              target='_blank'
            >
              Resultados Optimist Timoneles
            </a>
          </li>
          <li>
            <a
              href='https://drive.google.com/file/d/1SOF1ZsGHExmbL_LbxPR92Xxf0Za3ORzD/view?usp=share_link'
              rel='noreferrer'
              target='_blank'
            >
              Resultados Optimist Principiantes
            </a>
          </li>
          <li>
            <a
              href='https://drive.google.com/file/d/1ccIM3zUgVoPcsrgRmDCp7z196N91jCSH/view?usp=sharing'
              rel='noreferrer'
              target='_blank'
            >
              Resultados ILCA 7
            </a>
          </li>
          <li>
            <a
              href='https://drive.google.com/file/d/1mFB3yYvF9_otpAGMrBsvDL_02DX_CI_N/view?usp=sharing'
              rel='noreferrer'
              target='_blank'
            >
              Resultados ILCA 6
            </a>
          </li>
          <li>
            <a
              href='https://drive.google.com/file/d/1UiW9eJeNqd-xHTTz290LoqiXMAKsUQDk/view?usp=sharing'
              rel='noreferrer'
              target='_blank'
            >
              Resultados ILCA 4
            </a>
          </li>
          <li>
            <a
              href='https://drive.google.com/file/d/10DAIUepRFZNV41NCAkbCG_lCRR4QsQAN/view?usp=drivesdk'
              rel='noreferrer'
              target='_blank'
            >
              Resultados Finn
            </a>
          </li>
          <li>
            <a
              href='https://drive.google.com/file/d/18ryK3LUeqDJZ7JA7fU7MRWBHfV0unVO2/view?usp=drivesdk'
              rel='noreferrer'
              target='_blank'
            >
              Resultados F18
            </a>
          </li>
          <li>
            <a
              href='https://drive.google.com/file/d/1AI2twfCmWijM28SWNPB_UhmZdEDKT9gY/view?usp=share_link'
              rel='noreferrer'
              target='_blank'
            >
              Resultados Cadet
            </a>
          </li>
          <li>
            <a
              href='https://drive.google.com/file/d/1WJdT2zVZoZB1V_uJF9iXg3K98k-WZAwJ/view?usp=sharing'
              rel='noreferrer'
              target='_blank'
            >
              Resultados 420
            </a>
          </li>
          <li>
            <a
              href='https://drive.google.com/file/d/1NTZfTqqiBLGGgnHipH4HJietqZr4SsGm/view?usp=sharing'
              rel='noreferrer'
              target='_blank'
            >
              Resultados 29er
            </a>
          </li>
          <li>
            <a
              href='https://drive.google.com/file/d/1Mm38059HaPogqF8YT5z4cw2dF9YiTUrB/view?usp=sharing'
              rel='noreferrer'
              target='_blank'
            >
              Resultados Snipe
            </a>
          </li>
        </ul>
        <br />
        <Typography variant='h5'>Velafest Quilla 2023</Typography>
        <Typography className='text-ellipsis overflow-hidden whitespace-nowrap'>
          <b>Documentos:</b>{' '}
          <a
            href='https://drive.google.com/drive/folders/1_HeAsjv0s8gRGoU2lpHeaT5GF8I8bVOP?usp=sharing'
            className='text-ellipsis overflow-hidden whitespace-nowrap'
            rel='noreferrer'
            target='_blank'
          >
            https://drive.google.com/drive/folders/1_HeAsjv0s8gRGoU2lpHeaT5GF8I8bVOP
          </a>
        </Typography>
        <Typography>
          <b>Resultados:</b>
        </Typography>
        <ul
          style={{
            listStyle: 'inside url(/file-pdf-solid.svg)',
            marginLeft: '5%',
          }}
        >
          <li>
            <a
              href='https://drive.google.com/open?id=16ZKgPC3RLE45cQqm5xpBtmogA6bYoZKj'
              rel='noreferrer'
              target='_blank'
            >
              Resultados J24
            </a>
          </li>
          <li>
            <a
              href='https://drive.google.com/open?id=19SFEatHYip0dvnokxYSb8lollECM5tki'
              rel='noreferrer'
              target='_blank'
            >
              Resultados Star
            </a>
          </li>
          <li>
            <a
              href='https://drive.google.com/open?id=11wnNrGQMIU731l_jNwkmtzdm3vE_lwPF'
              rel='noreferrer'
              target='_blank'
            >
              Resultados Conte 24
            </a>
          </li>
        </ul>
      </Paper>
    </div>
  );
};

export default PreviosEditionsPage;
