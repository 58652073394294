import { Helmet } from 'react-helmet';
import DocumentosImg from '../assets/420-v2.jpg';
import ResultadosImg from '../assets/optimist-v2.jpg';
import FotosImg from '../assets/laser-pancho.jpg';

import Bubble from '../components/Bubble';

export default function HomePage() {
  const bubbles: {
    text: string;
    img: string;
    link: string;
    openInBlank: boolean;
  }[] = [
    // {
    //   text: 'Documentos',
    //   img: '/documentos.webp',
    //   link: '/documentos',
    //   openInBlank: false,
    // },
    // {
    //   text: 'Resultados',
    //   img: '/resultados.webp',
    //   link: '/resultados',
    //   openInBlank: false,
    // },
    // {
    //   text: 'Fotos',
    //   img: 'fotos.webp',
    //   link: '',
    //   openInBlank: false,
    // },
    {
      text: 'Documentos',
      img: '/grumetes.webp',
      link: '/documentos',
      openInBlank: false,
    },
    {
      text: 'Resultados',
      img: '/resultados.webp',
      link: '/resultados',
      openInBlank: false,
    },
    {
      text: 'Fotos',
      img: '/fotos-hd.webp',
      link: 'https://sites.google.com/view/vela-fest-rio-de-la-plata/fotografia',
      openInBlank: false,
    },
    {
      text: 'Telegram',
      img: '/telegram.jpg',
      link: 'https://t.me/velafestquilla2024',
      openInBlank: true,
    },
  ];

  return (
    <div className='w-full'>
      <Helmet>
        <title>Inicio | Campeonato Velafest</title>
        <meta
          name='description'
          content='Bienvenido! El campeonato Velafest es un campeonato que se realiza en el rio de la plata, organizado en conjunto ente el Club de Veleros Barlovento y el Club Náutico Albatros.'
        />
      </Helmet>
      <picture>
        <source
          media='(min-width: 768px)'
          srcSet='/2024_quilla_web.png'
          className='min-width-screen aspect-auto'
        />
        <img src='/2024_quilla_mobile.png' alt='Velafest banner teléfono' className='w-full' />
      </picture>

      {bubbles.length !== 0 && (
        <div className='w-full justify-center'>
          <div className='flex flex-row flex-wrap w-full justify-center py-8 overflow-hidden'>
            {bubbles.map((bubble, index: number) => {
              return (
                <div className='basis-1 md:basis-1/2 w-fit justify-center flex' key={index}>
                  <Bubble
                    text={bubble.text}
                    img={bubble.img}
                    link={bubble.link}
                    openInBlank={bubble.openInBlank}
                  />
                </div>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
}
