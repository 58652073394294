import LogoVelafest from '../assets/velafest-logo.svg';
import { NavLink, Link } from 'react-router-dom';
import { useCookies } from 'react-cookie';

export default function NavBar(props: { pages: any }) {
  const [cookies] = useCookies(['jwt']);

  const activeNavBarElement =
    'block py-2 pl-3 pr-4 text-white bg-vforange rounded md:bg-transparent md:text-vforange md:p-0';
  const inactiveNavBarElement =
    'block py-2 pl-3 pr-4 text-gray-700 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0';

  const pageItemList = Object.keys(props.pages).map((pageIndex: any, index: number) => {
    if (props.pages[pageIndex].isVisible) {
      if (props.pages[pageIndex].adminRoute === true && !cookies.jwt) return;

      return (
        <li key={index}>
          {props.pages[pageIndex].title === 'Vela Fest 2023 Orza' ? (
            <a
              href={props.pages[pageIndex].url}
              className={inactiveNavBarElement}
              target='_blank'
              rel='noreferrer'
            >
              {props.pages[pageIndex].title}
            </a>
          ) : (
            <NavLink
              to={props.pages[pageIndex].url}
              className={({ isActive }) => (isActive ? activeNavBarElement : inactiveNavBarElement)}
            >
              {props.pages[pageIndex].title}
            </NavLink>
          )}
        </li>
      );
    }
  });

  return (
    <nav className='bg-white border-gray-400 px-2 sm:px-4 py-2.5'>
      <div className='bg-transparent container flex flex-wrap items-center justify-between mx-auto'>
        <div className='flex flex-grow justify-start'>
          <Link to='/' className='flex items-center'>
            <img src={LogoVelafest} className='h-[70px] mr-3' alt='Velafest Logo' />
            {/* <span className='self-center text-md md:text-xl font-semibold whitespace-nowrap'>
            2023 Rio de la Plata Vela Fest
          </span> */}
          </Link>
        </div>
        <button
          data-collapse-toggle='navbar-default'
          type='button'
          className='inline-flex items-center p-2 ml-3 text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200'
          aria-controls='navbar-default'
          aria-expanded='false'
        >
          <span className='sr-only'>Open main menu</span>
          <svg
            className='w-6 h-6'
            aria-hidden='true'
            fill='currentColor'
            viewBox='0 0 20 20'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              fillRule='evenodd'
              d='M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z'
              clipRule='evenodd'
            ></path>
          </svg>
        </button>
        <div className='hidden w-full md:block md:w-auto justify-end' id='navbar-default'>
          <ul className='flex flex-col p-4 mt-4 border border-gray-100 rounded-lg bg-gray-50 md:flex-row md:space-x-8 md:mt-0 md:text-sm md:font-medium md:border-0 md:bg-white'>
            {pageItemList}
          </ul>
        </div>
      </div>
    </nav>
  );
}
