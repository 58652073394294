import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';

import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';

import { useDispatch, useSelector } from 'react-redux';
import {
  changeNewRequestFormValues,
  selectAllNewRequestFormValues,
  resetValuesToInitState,
  Competitor,
} from '../../../redux/features/requests/requestsSlice';
import NewAudiencePartial from './partials/NewAudiencePartial';
import {
  useCreateNewAudienceMutation,
  useCreateNewEquipmentChangeMutation,
  useCreateNewResultsReviewMutation,
} from '../../../redux/service/velafestApi';
import { ScreenLoadingOrganism } from '../../UI/organisms/ScreenLoadingOrganism';
import { Audience } from '../../../entities/requests/audience.entity';
import NewResultsReviewPartial from './partials/NewResultsReviewPartial';
import { ResultsReview } from '../../../entities/requests/resultsReview.entity';
import NewEquipmentChangePartial from './partials/NewEquipmentChangePartial';
import { EquipmentChange } from '../../../entities/requests/equipmentChange.entity';

export default function NewRequestDialog({ isOpen, setIsOpen }: { isOpen: any; setIsOpen: any }) {
  const dispatch = useDispatch();
  const newRequestFormValues = useSelector(selectAllNewRequestFormValues);
  const [createAudience, { isLoading }] = useCreateNewAudienceMutation();
  const [createResultsReview, createResultsReviewExtras] = useCreateNewResultsReviewMutation();
  const [createEquipmentChange, createEquipmentChangeExtras] =
    useCreateNewEquipmentChangeMutation();

  const handlers = {
    handleClose: () => {
      setIsOpen(false);
    },
    handleAccept: async () => {
      if (newRequestFormValues.requestType === 'AUDIENCE') {
        console.info('Creating new Audience');
        const audience: Audience = {
          type: newRequestFormValues.audienceType,
          requester: {
            category: newRequestFormValues.requesterBoat.category || '',
            sailNumber: newRequestFormValues.requesterBoat.sailNumber || '',
          },
          participants: newRequestFormValues.protestees.map((competitor: Competitor) => {
            return {
              category: competitor.category || '',
              sailNumber: competitor.sailNumber || '',
            };
          }),
          incident: {
            dateTime: newRequestFormValues.incidentTime || '',
            raceDate: newRequestFormValues.eventRaceDate || '',
            raceNumber: newRequestFormValues.eventRaceNumber || '',
            infringedRules: newRequestFormValues.incidentRulesInringed || '',
            witnesses: newRequestFormValues.incidentWitnesses.map((competitor: Competitor) => {
              return {
                category: competitor.category || '',
                sailNumber: competitor.sailNumber || '',
              };
            }),
          },
          informed: {
            hail: {
              hailed: newRequestFormValues.hailHailedOutloud,
              wordsUsed: newRequestFormValues.hailWordsUsed || '',
              situation: newRequestFormValues.hailSituation || '',
            },
            flagDisplayed: {
              flagDisplayed: newRequestFormValues.redFlag,
              situation: newRequestFormValues.redFlagSituation || '',
            },
            informedOtherWay: {
              informedOtherWay: newRequestFormValues.informedOtherWay,
              situation: newRequestFormValues.informedOtherWayWordsUsed || '',
            },
          },
          description: newRequestFormValues.incidentDescription || '',
        };

        try {
          const payload = await createAudience({ audience }).unwrap();
          if (payload) setIsOpen(false);
        } catch (error) {
          console.error('rejected', error);
        }
      } else if (newRequestFormValues.requestType === 'RESULTSREVIEW') {
        console.info('Creating new ResultsReview');

        const resultsReview: ResultsReview = {
          requester: {
            category: newRequestFormValues.requesterBoat.category || '',
            sailNumber: newRequestFormValues.requesterBoat.sailNumber || '',
          },
          raceNumber: newRequestFormValues.eventRaceNumber || '',
          actualResult: newRequestFormValues.actualResult,
          requestedResult: newRequestFormValues.requestedResult,
          requestDescription: newRequestFormValues.request || '',
        };

        try {
          const payload = await createResultsReview({ resultsReview }).unwrap();
          if (payload) setIsOpen(false);
        } catch (error) {
          console.error('rejected', error);
        }
      } else if (newRequestFormValues.requestType === 'EQUIPMENTCHANGE') {
        const equipmentChange: EquipmentChange = {
          requester: {
            category: newRequestFormValues.requesterBoat.category || '',
            sailNumber: newRequestFormValues.requesterBoat.sailNumber || '',
          },
          request: newRequestFormValues.request || '',
        };

        try {
          const payload = await createEquipmentChange({ equipmentChange }).unwrap();
          if (payload) setIsOpen(false);
        } catch (error) {
          console.error('rejected', error);
        }
      }
    },

    handleRequestTypeSelectChange: (e: SelectChangeEvent) => {
      const requestType = e.target.value as string;

      if (newRequestFormValues.requestType !== requestType) {
        dispatch(resetValuesToInitState(null));
      }

      dispatch(
        changeNewRequestFormValues({
          key: 'requestType',
          value: requestType,
        }),
      );
    },
  };

  if (isLoading || createResultsReviewExtras.isLoading)
    return <ScreenLoadingOrganism isOpen={true} />;

  return (
    <Dialog open={isOpen} onClose={handlers.handleClose} fullWidth>
      <form onSubmit={handlers.handleAccept}>
        <DialogTitle>Nueva Solicitud</DialogTitle>
        <DialogContent>
          <FormControl fullWidth margin='normal'>
            <InputLabel id='request-label'>Tipo de Solicitud</InputLabel>
            <Select
              name=''
              labelId='request-label'
              id='request-select'
              label='Tipo de Solicitud'
              variant='outlined'
              value={newRequestFormValues.requestType}
              onChange={handlers.handleRequestTypeSelectChange}
              required
            >
              <MenuItem value={'AUDIENCE'}>Audiencia</MenuItem>
              <MenuItem value={'RESULTSREVIEW'}>Revision de resultados</MenuItem>
              <MenuItem value={'EQUIPMENTCHANGE'}>Cambio de equipo</MenuItem>
            </Select>
          </FormControl>
          {newRequestFormValues.requestType === 'AUDIENCE' && <NewAudiencePartial />}
          {newRequestFormValues.requestType === 'RESULTSREVIEW' && <NewResultsReviewPartial />}
          {newRequestFormValues.requestType === 'EQUIPMENTCHANGE' && <NewEquipmentChangePartial />}
        </DialogContent>
        <DialogActions>
          <Button onClick={handlers.handleClose}>Cancelar</Button>
          <Button type='submit'>Guardar</Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}
