import { Config } from '../config';
import { IAudienceRequest } from './IRequestsModule';

export class VFRequests {
  bearerToken = '';

  constructor(bearerToken = '') {
    if (bearerToken) {
      this.bearerToken = bearerToken;
    }
  }

  async getAudienceRequests(): Promise<IAudienceRequest[]> {
    return await fetch(`${Config.urls.modules.requests}/audience`)
      .then((res) => {
        return res.json();
      })
      .then((res) => {
        return res.data;
      });
  }

  async getAudienceRequestById(id: string): Promise<IAudienceRequest> {
    return await fetch(`${Config.urls.modules.requests}/audience/` + id)
      .then((res) => {
        return res.json();
      })
      .then((res) => {
        return res.data;
      });
  }
}
