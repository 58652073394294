import { useEffect, useMemo, useState } from 'react';
import { useCookies } from 'react-cookie';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';
import Alert from '@mui/material/Alert';
import { VFAuth } from '../../lib/vf-sdk/Auth/AuthenticationModule';
import { showAlert } from '../../utils/showAlert';

export default function LoginPage() {
  const [, setCookie] = useCookies(['jwt']);

  const [user, setUser] = useState('');
  const [pass, setPass] = useState('');

  const [errorAlertIsOpen, setErrorAlertIsOpen] = useState<boolean>(false);
  const [errorAlertMessage, setErrorAlertMessage] = useState<string>('');

  function useQuery() {
    const { search } = useLocation();

    return useMemo(() => new URLSearchParams(search), [search]);
  }

  const queryParams = useQuery();
  const errorMessage = queryParams.get('errorMessage');
  const redirectURI = queryParams.get('redirectURI');
  //

  useEffect(() => {
    if (errorMessage) {
      setErrorAlertIsOpen(true);
      setErrorAlertMessage(errorMessage);
    }
  }, [errorMessage]);

  const onChangeUser = (event: any) => {
    setUser(event.target.value);
  };

  const onChangePass = (event: any) => {
    setPass(event.target.value);
  };

  const handleSubmit = (event: any) => {
    event.preventDefault();

    const data = {
      username: user,
      password: pass,
    };

    new VFAuth()
      .basicAuth(data)
      .then((res) => {
        // //
        setCookie('jwt', res.access_token, { path: '/' });
        if (redirectURI) {
          //
          window.location.href = decodeURI(redirectURI);
        } else {
          window.location.href = '/';
        }
      })
      .catch((reason) => {
        showAlert({
          type: 'error',
          message: reason.message,
        });
      });
  };

  return (
    <div className='grid place-items-center h-full'>
      <Helmet>
        <title>Login Admin | Campeonato Velafest</title>
        <meta
          name='description'
          content='Inicio de sesión para administradores del Campeonato Velafest (sección solo para administradores). El campeonato Velafest es un campeonato que se realiza en el rio de la plata, organizado en conjunto ente el Club de Veleros Barlovento y el Club Náutico Albatros.'
        />
        <meta name='robots' content='noindex' />
      </Helmet>
      <div className='w-full max-w-sm p-4 bg-white border border-gray-200 rounded-lg shadow-md sm:p-6 md:p-8'>
        <form className='space-y-6' onSubmit={handleSubmit}>
          <h5 className='text-xl font-medium text-gray-900'>Login Administrador</h5>
          <div>
            <label htmlFor='user' className='block mb-2 text-sm font-medium text-gray-900'>
              Usuario
            </label>
            <input
              type='user'
              name='user'
              id='user'
              value={user}
              onChange={onChangeUser}
              placeholder='admin'
              className='bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 '
              required
            />
          </div>
          <div>
            <label htmlFor='password' className='block mb-2 text-sm font-medium text-gray-900'>
              Contraseña
            </label>
            <input
              type='password'
              name='password'
              id='password'
              value={pass}
              onChange={onChangePass}
              placeholder='••••••••'
              className='bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 '
              required
            />
          </div>
          <button
            type='submit'
            className='w-full text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center'
          >
            Iniciar Sesión
          </button>
        </form>
      </div>
      {errorAlertIsOpen && (
        <Alert
          className='absolute right-4 bottom-4'
          severity='error'
          onClose={() => {
            setErrorAlertIsOpen(false);
          }}
        >
          {errorAlertMessage}
        </Alert>
      )}
    </div>
  );
}
