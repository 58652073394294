import { ResultsReview } from '../../../entities/requests/resultsReview.entity';
import { useGetAllResultsReviewRequestsQuery } from '../../../redux/service/velafestApi';
import { ScreenLoadingOrganism } from '../../UI/organisms/ScreenLoadingOrganism';
import ResultsReviewAccordion from './ResultsReviewAccordion';
import ResultsReviewStatusUpdate from './ResultsReviewStatusUpdateDialog';

export default function ResultsReviewList() {
  const {
    data: resultsReviewList,
    isError: resultsReviewIsError,
    error: resultsReviewError,
    isLoading: resultsReviewIsLoading,
  } = useGetAllResultsReviewRequestsQuery('');

  // const resultsReviewList: ResultsReview[] = [
  //   {
  //     resultsReviewId: 1,
  //     requester: {
  //       category: 'Optimist Timoneles',
  //       sailNumber: 'ARG3178',
  //     },
  //     raceDate: new Date().toString(),
  //     raceNumber: '5',
  //     actualResult: 'DNF',
  //     requestedResult: 'Entre ARG3855 y ARG2155',
  //     requestDescription:
  //       'Se me asigno DNF, pero entre correctamente entre el ARG3855 y el ARG2155 entre los primeros 15',
  //     status: [
  //       {
  //         status: 'Pendiente',
  //         date: new Date().toString(),
  //       },
  //     ],
  //   },
  //   {
  //     resultsReviewId: 2,
  //     requester: {
  //       category: '420',
  //       sailNumber: 'ARG57027',
  //     },
  //     raceDate: new Date().toString(),
  //     raceNumber: '3',
  //     actualResult: 'UFD',
  //     requestedResult: 'No pasado',
  //     requestDescription: 'Se me puso UFD cuando durante el ultimo minuto estaba 3 esloras atras',
  //     status: [
  //       {
  //         status: 'Denegada',
  //         date: new Date().toString(),
  //         comment:
  //           'Se revisaron las grabaciones y se tiene tomado al ARG57027 como UFD faltando aproximadamente 40 segundos',
  //       },
  //       {
  //         status: 'Pendiente',
  //         date: new Date().toString(),
  //       },
  //     ],
  //   },
  //   {
  //     resultsReviewId: 3,
  //     requester: {
  //       category: 'ILCA 6',
  //       sailNumber: 'ARG195397',
  //     },
  //     raceDate: new Date().toString(),
  //     raceNumber: '6',
  //     actualResult: 'BFD',
  //     requestedResult: 'No pasado',
  //     requestDescription:
  //       'Se me puso UFD cuando durante el ultimo minuto estaba atras de la lancha',
  //     status: [
  //       {
  //         status: 'Otorgada',
  //         date: new Date().toString(),
  //         comment: 'Se concede la solicitud y se asigna la posicion tomada en la llegada',
  //       },
  //       {
  //         status: 'Pendiente',
  //         date: new Date().toString(),
  //       },
  //     ],
  //   },
  // ].sort((a, b) => {
  //   return b.resultsReviewId - a.resultsReviewId;
  // });

  if (resultsReviewIsLoading) return <ScreenLoadingOrganism isOpen={resultsReviewIsLoading} />;

  if (resultsReviewIsError) console.error(resultsReviewError);

  return (
    <div className='flex flex-col justify-center p-8 md:w-3/4 lg:w-1/2 w-full'>
      {resultsReviewList.data
        .map((resultsReview: ResultsReview, index: number) => {
          return <ResultsReviewAccordion resultsReview={resultsReview} key={index} />;
        })
        .sort((a: ResultsReview, b: ResultsReview) => {
          return (b.resultsReviewId || 0) - (a.resultsReviewId || 1);
        })}
    </div>
  );
}
