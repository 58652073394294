import ReactDOM from 'react-dom';
import Alert from '@mui/material/Alert';

export const showAlert = (params: { type: string; message: string; showTime?: number }) => {
  const alertShowtime = params.showTime ? params.showTime * 1000 : 10000;
  const alertClasses = 'absolute right-4 bottom-4';

  let alertComponent: any;

  const handleAlertOnClose = () => {
    ReactDOM.render(<div id='alertDiv' />, document.getElementById('alertDiv'));
  };

  switch (params.type) {
    case 'success':
      alertComponent = (
        <Alert severity='success' className={alertClasses} onClose={handleAlertOnClose}>
          {params.message}
        </Alert>
      );
      break;

    case 'danger':
    case 'error':
      alertComponent = (
        <Alert severity='error' className={alertClasses} onClose={handleAlertOnClose}>
          {params.message}
        </Alert>
      );
      break;

    case 'warning':
      alertComponent = (
        <Alert severity='warning' className={alertClasses} onClose={handleAlertOnClose}>
          {params.message}
        </Alert>
      );
      break;

    case 'info':
      alertComponent = (
        <Alert severity='info' className={alertClasses} onClose={handleAlertOnClose}>
          {params.message}
        </Alert>
      );
      break;

    default:
      break;
  }

  ReactDOM.render(alertComponent, document.getElementById('alertDiv'));
  // ReactDOM.render(<DangerAlert text={params.message} />, document.getElementById('alertDiv'));
  setTimeout(() => {
    ReactDOM.render(<div id='alertDiv' />, document.getElementById('alertDiv'));
  }, alertShowtime);
};
