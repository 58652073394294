import {
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
  Divider,
  IconButton,
  Stack,
} from '@mui/material';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { EquipmentChange } from '../../../entities/requests/equipmentChange.entity';
import { generateAudienceParticipantString } from '../../../utils/audienceUtils';
import EditIcon from '@mui/icons-material/Edit';
import { useCookies } from 'react-cookie';

import EquipmentChangeStatusUpdate from './EquipmentChangeStatusUpdateDialog';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { changeNewRequestFormValues } from '../../../redux/features/requests/requestsSlice';

export default function EquipmentChangeAccordion({
  equipmentChange,
}: {
  equipmentChange: EquipmentChange;
}) {
  const [editStatusDialogIsOpen, setEditStatusDialogIsOpen] = useState<boolean>(false);
  const [cookies] = useCookies(['jwt']);
  const isAdmin = cookies.jwt ? true : false;
  const dispatch = useDispatch();

  const handlers = {
    handleAudienceStatusChange: (_id: string) => {
      setEditStatusDialogIsOpen(true);

      dispatch(
        changeNewRequestFormValues({
          key: '_id',
          value: _id,
        }),
      );
    },
  };

  return (
    <>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls='panel1a-content'
          id='panel1a-header'
        >
          <div className='w-full flex'>
            <Typography className='basis-1/2' style={{ fontWeight: 'bold' }}>
              Cambio de Equipo {equipmentChange.equipmentChangeId}
            </Typography>
            {equipmentChange.status && (
              <Typography className='basis-1/2'>{equipmentChange.status[0].status}</Typography>
            )}
          </div>
        </AccordionSummary>
        <AccordionDetails>
          {isAdmin && (
            <div className='sm:hidden flex flex-col' style={{ marginTop: '-25px' }}>
              <Stack direction='row' alignItems='left' spacing={1}>
                <IconButton
                  color='primary'
                  aria-label='upload picture'
                  component='label'
                  onClick={() => {
                    if (equipmentChange._id)
                      handlers.handleAudienceStatusChange(equipmentChange._id);
                  }}
                >
                  <EditIcon fontSize='large' />
                </IconButton>
              </Stack>
            </div>
          )}
          <div className='flex flex-row'>
            <div className='flex flex-col sm:basis-5/6'>
              <div className='flex flex-row py-1.5'>
                <h1 className='font-bold'>Solicitante:</h1>
                <p className='px-2 pt-px mt-px'>
                  {generateAudienceParticipantString(equipmentChange.requester)}
                </p>
              </div>
              <div className='flex flex-row py-1.5'>
                <h1 className='font-bold'>Solicitud:</h1>
                <p className='px-2 pt-px mt-px'>{equipmentChange.request}</p>
              </div>
              {equipmentChange.status && equipmentChange.status[0].comment && (
                <>
                  <br />
                  <Divider />
                  <br />
                  <div className='flex flex-row py-1.5'>
                    <h1 className='font-bold'>Resolucion:</h1>
                    <p className='px-2 pt-px mt-px'>
                      {equipmentChange.status ? equipmentChange.status[0].comment : ''}
                    </p>
                  </div>
                </>
              )}
            </div>
            {isAdmin && (
              <div className='sm:flex flex-col hidden sm:basis-1/6'>
                <IconButton
                  color='primary'
                  aria-label='upload picture'
                  component='label'
                  onClick={() => {
                    if (equipmentChange._id)
                      handlers.handleAudienceStatusChange(equipmentChange._id);
                  }}
                >
                  <EditIcon fontSize='large' />
                </IconButton>
              </div>
            )}
          </div>
        </AccordionDetails>
      </Accordion>
      <EquipmentChangeStatusUpdate
        isOpen={editStatusDialogIsOpen}
        setIsOpen={setEditStatusDialogIsOpen}
      />
    </>
  );
}
