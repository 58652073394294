import { Config } from '../config';
import { ICreateResultDto, IResultRes } from './IResultsModule';

export class VFResults {
  bearerToken = '';

  constructor(bearerToken = '') {
    if (bearerToken) {
      this.bearerToken = bearerToken;
    }
  }

  resultsAreEnabled(): Promise<{ enabled: boolean }> {
    return fetch(Config.urls.modules.results + '/enabled', {
      mode: 'cors',
      method: 'GET',
    })
      .then((res) => {
        switch (res.status) {
          case 200:
            return res.json();
            break;

          default:
            throw new Error(`Unknown error. Code: ${res.status} ${res.statusText}`);
            break;
        }
      })
      .then((res) => {
        return res.data;
      });
  }

  getAllResults(): Promise<IResultRes[]> {
    return fetch(Config.urls.modules.results, {
      mode: 'cors',
      method: 'GET',
    })
      .then((res) => {
        switch (res.status) {
          case 200:
            return res.json();
            break;

          default:
            throw new Error(`Unknown error. Code: ${res.status} ${res.statusText}`);
            break;
        }
      })
      .then((res) => {
        return res.data;
      });
  }

  getResultById(id: string): Promise<IResultRes> {
    return fetch(Config.urls.modules.results + '/' + id, {
      mode: 'cors',
      method: 'GET',
    })
      .then((res) => {
        switch (res.status) {
          case 200:
            return res.json();
            break;

          default:
            throw new Error(`Unknown error. Code: ${res.status} ${res.statusText}`);
            break;
        }
      })
      .then((res) => {
        return res.data;
      });
  }

  createResult(
    result: ICreateResultDto,
    options: { bearerToken: string } = { bearerToken: this.bearerToken },
  ): Promise<{ success: boolean; result: any }> {
    this.bearerToken = options.bearerToken;

    return fetch(Config.urls.modules.results, {
      mode: 'cors',
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + this.bearerToken,
      },
      body: JSON.stringify(result),
    })
      .then((res) => {
        switch (res.status) {
          case 201:
            return res.json();
            break;

          default:
            throw new Error(`Unknown error. Code: ${res.status} ${res.statusText}`);
            break;
        }
      })
      .then((res) => {
        return res.data;
      });
  }

  uploadResult(
    result: {
      sailingClass: string,
      file: any
    },
    options: { bearerToken: string } = { bearerToken: this.bearerToken },
  ): Promise<{ success: boolean; result: any }> {
    this.bearerToken = options.bearerToken;

    const formData = new FormData();
    formData.append('sailingClass', result.sailingClass);
    formData.append('file', result.file);

    return fetch(Config.urls.modules.results + '/upload', {
      mode: 'cors',
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + this.bearerToken,
      },
      body: formData
    })
      .then((res) => {
        switch (res.status) {
          case 201:
            return res.json();
            break;

          default:
            throw new Error(`Unknown error. Code: ${res.status} ${res.statusText}`);
            break;
        }
      })
      .then((res) => {
        return res.data;
      });
  }

  deleteResult(
    id: string,
    options: { bearerToken: string } = { bearerToken: this.bearerToken },
  ): Promise<{ success: boolean; id: string }> {
    this.bearerToken = options.bearerToken;

    return fetch(Config.urls.modules.results + '/' + id, {
      mode: 'cors',
      method: 'DELETE',
      headers: {
        Authorization: 'Bearer ' + this.bearerToken,
      },
    })
      .then((res) => {
        switch (res.status) {
          case 200:
            return res.json();
            break;

          case 401:
            throw new Error('Unauthorized');
            break;

          default:
            throw new Error(`Unknown error. Code: ${res.status} ${res.statusText}`);
            break;
        }
      })
      .then((res) => {
        return res.data;
      });
  }
}
