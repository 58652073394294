import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';

import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import PendingStatusChangeFormPartial from './statusChange/PendingStatusChangeFormPartial';

import { useDispatch, useSelector } from 'react-redux';
import {
  useUpdateAudienceStatusToScheduledMutation,
  useUpdateAudienceStatusToProccessedMutation,
} from '../../../redux/service/velafestApi';
import {
  changeFormValue,
  selectAllFormData,
} from '../../../redux/features/requests/audiences/audiencesSlice';
import { AudienceStatusEnum, JuryPosition } from '../../../entities/requests/audience.entity';
import { ScreenLoadingOrganism } from '../../UI/organisms/ScreenLoadingOrganism';
import { useCookies } from 'react-cookie';

import { format } from 'date-fns';
import { Typography } from '@mui/material';
import ClosedStatusChangeFormPartial from './statusChange/ClosedStatusChangeFormPartial';

export default function AudienceStatusUpdateOrganism({
  status,
  setStatus,
  isOpen,
  handleClose,
  handleAccept,
  setIsOpen,
}: {
  status: string;
  setStatus: any;
  isOpen: any;
  handleClose: any;
  handleAccept: any;
  setIsOpen: any;
}) {
  const [cookies] = useCookies(['jwt']);

  const [updateStatus, { isLoading: statusIsUpdating }] =
    useUpdateAudienceStatusToScheduledMutation();

  const [updateStatusToProccessed, { isLoading: statusIsUpdatingToProccessed }] =
    useUpdateAudienceStatusToProccessedMutation();

  const statusUpdateFormData = useSelector(selectAllFormData);
  const dispatch = useDispatch();

  const handlers = {
    // handleStatusChange: (e: SelectChangeEvent) => {
    //   setFormValues({
    //     ...formValues,
    //     status: e.target.value as string
    //   });
    // },
    handleAccept: (e: any) => {
      e.preventDefault();

      switch (statusUpdateFormData.status) {
        case status:
          return;

        case AudienceStatusEnum.PENDING:
          updateStatus({
            bearer: cookies.jwt,
            id: statusUpdateFormData._id,
            status: statusUpdateFormData.status,
            comment: 'PENDING',
          });

          setIsOpen(true);
          break;

        case AudienceStatusEnum.SCHEDULED: {
          const formData = statusUpdateFormData;

          if (formData.date === null) {
            break;
          }

          // const localDate: Date = new Date(formData.date);

          updateStatus({
            bearer: cookies.jwt,
            id: formData._id,
            status: formData.status,
            comment: `Planificada para ${formData.date}`
          });

          setIsOpen(false);
          break;
        }

        case AudienceStatusEnum.PROCCESSED: {
          updateStatusToProccessed({
            bearer: cookies.jwt,
            id: statusUpdateFormData._id,
            status: statusUpdateFormData.status,
            resolution: {
              timeLimit: {
                timeLimit:
                  statusUpdateFormData.presentationTimeLimit ||
                  'Could not save Date on status update',
                presentationWindowExpanded: statusUpdateFormData.protestTimeLimitExtended,
                presentedOnTime: statusUpdateFormData.protestFiledInTime,
              },

              participants: {
                requesterRepresentedBy: statusUpdateFormData.requesterRepresentedBy || '',
                protesteesRepresentedBy: statusUpdateFormData.protestees || '',
                witnesses: statusUpdateFormData.witnesses || '',
                interpreters: statusUpdateFormData.interpreters || '',
              },

              validity: {
                interestedPartyObjected: {
                  checked: statusUpdateFormData.validityObjectionChecked,
                  observation: statusUpdateFormData.validityObjectionObservation || '',
                },
                theWrittenDeclarationDescribesTheIncident: {
                  checked: statusUpdateFormData.validityTextRepresentsIncidentChecked,
                  observation: statusUpdateFormData.validityTextRepresentsIncidentObservation || '',
                },
                hailedOutLoud: {
                  checked: statusUpdateFormData.validityHailedOutLoudChecked,
                  observation: statusUpdateFormData.validityHailedOutLoudObservation || '',
                },
                advicedAtFirstReasonableOpportunity: {
                  checked: statusUpdateFormData.validityNotifiedAtFirstOportunityChecked,
                  observation:
                    statusUpdateFormData.validityNotifiedAtFirstOportunityObservation || '',
                },
                redFlagWasDisplayedAtFirstOpportunity: {
                  checked: statusUpdateFormData.validityRedFlagDisplayedChecked,
                  observation: statusUpdateFormData.validityRedFlagDisplayedObservation || '',
                },
                redFlagWasSeenByRaceCommitee: {
                  checked: statusUpdateFormData.validityRedFlagSeenByRaceCommiteeChecked,
                  observation:
                    statusUpdateFormData.validityRedFlagSeenByRaceCommiteeObservation || '',
                },
                isValid: statusUpdateFormData.validityIsAudienceValid,
              },

              proceduralMatters: statusUpdateFormData.proceduralMatters || '',
              provedFacts: statusUpdateFormData.provedFacts || '',
              conclusionAndRulesThatApply: statusUpdateFormData.largeConclusion || '',

              resolution: statusUpdateFormData.longResolution || '',
              shortResolution: statusUpdateFormData.shortResolution || '',

              juryParticipants: statusUpdateFormData.juryMembers || '',
            },
          });

          setIsOpen(false);
          break;
        }

        case AudienceStatusEnum.RETIRED: {
          updateStatusToProccessed({
            bearer: cookies.jwt,
            id: statusUpdateFormData._id,
            status: statusUpdateFormData.status,
            resolution: {
              retired: true,
              shortResolution: 'Solicitud Retirada',
            },
          });

          setIsOpen(false);
          break;
        }

        default:
          break;
      }
    },
  };

  if (statusIsUpdating) return <ScreenLoadingOrganism isOpen={statusIsUpdating} />;

  return (
    <Dialog open={isOpen} onClose={handleClose} fullWidth>
      <form onSubmit={handlers.handleAccept}>
        <DialogTitle>Actualizar estado</DialogTitle>
        <DialogContent>
          <FormControl fullWidth margin='normal'>
            <InputLabel id='status-label'>Status</InputLabel>
            <Select
              name=''
              labelId='status-label'
              id='status-select'
              label='Status'
              variant='outlined'
              value={statusUpdateFormData.status}
              onChange={(e: SelectChangeEvent) => {
                const value = e.target.value as string;
                dispatch(
                  changeFormValue({
                    key: 'status',
                    value,
                  }),
                );
              }}
              required
            >
              <MenuItem value={'PENDING'}>Pendiente</MenuItem>
              <MenuItem value={'SCHEDULED'}>Planificada</MenuItem>
              <MenuItem value={'PROCCESSED'}>Tratada</MenuItem>
              <MenuItem value={'RETIRED'}>Retirada</MenuItem>
            </Select>
          </FormControl>
          {statusUpdateFormData.status === 'SCHEDULED' && <PendingStatusChangeFormPartial />}
          {statusUpdateFormData.status === 'PROCCESSED' && <ClosedStatusChangeFormPartial />}
          {statusUpdateFormData.status === 'PENDING' && (
            <Typography>La audiencia pasara al estado Pendiente</Typography>
          )}
          {statusUpdateFormData.status === 'RETIRED' && (
            <Typography>La audiencia pasara al estado Retirada</Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancelar</Button>
          <Button type='submit'>Guardar</Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}
