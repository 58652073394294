import {
  Dialog,
  DialogTitle,
  DialogContent,
  FormControl,
  InputLabel,
  MenuItem,
  DialogActions,
  Select,
  Button,
  TextField,
} from '@mui/material';
import { useCookies } from 'react-cookie';
import { useDispatch, useSelector } from 'react-redux';
import {
  changeNewRequestFormValues,
  selectAllNewRequestFormValues,
} from '../../../redux/features/requests/requestsSlice';
import { useUpdateResultsReviewStatusMutation } from '../../../redux/service/velafestApi';
import { ScreenLoadingOrganism } from '../../UI/organisms/ScreenLoadingOrganism';

export default function ResultsReviewStatusUpdate({
  isOpen,
  setIsOpen,
}: {
  isOpen: any;
  setIsOpen: any;
}) {
  const [cookies] = useCookies(['jwt']);
  const dispatch = useDispatch();
  const newRequestFormValues = useSelector(selectAllNewRequestFormValues);
  const [updateStatus, { isLoading }] = useUpdateResultsReviewStatusMutation();

  const handlers = {
    handleAccept: async () => {
      try {
        const payload = await updateStatus({
          bearer: cookies.jwt,
          id: newRequestFormValues._id,
          status: newRequestFormValues.resultsReviewStatus,
          comment: newRequestFormValues.requestResolution,
        }).unwrap();
        if (payload) setIsOpen(false);
      } catch (error) {
        console.error('rejected', error);
      }
    },
    handleClose: () => {
      setIsOpen(false);
    },
  };

  if (isLoading) return <ScreenLoadingOrganism isOpen={isLoading} />;

  return (
    <Dialog open={isOpen} onClose={handlers.handleClose} fullWidth>
      <form onSubmit={handlers.handleAccept}>
        <DialogTitle>Nueva Solicitud</DialogTitle>
        <DialogContent>
          <FormControl fullWidth margin='normal'>
            <InputLabel id='status-label'>Status</InputLabel>
            <Select
              labelId='status-label'
              id='request-status-select'
              label='Estado'
              variant='outlined'
              value={newRequestFormValues.resultsReviewStatus}
              onChange={(e: any) => {
                dispatch(
                  changeNewRequestFormValues({
                    key: 'resultsReviewStatus',
                    value: e.target.value as string,
                  }),
                );
              }}
              required
            >
              <MenuItem value={'Pendiente'}>Pendiente</MenuItem>
              <MenuItem value={'Otorgada'}>Otorgada</MenuItem>
              <MenuItem value={'Denegada'}>Denegada</MenuItem>
            </Select>
          </FormControl>
          {newRequestFormValues.resultsReviewStatus !== 'Pendiente' && (
            <TextField
              label='Resolucion de la CR'
              value={newRequestFormValues.requestResolution}
              onChange={(e) => {
                dispatch(
                  changeNewRequestFormValues({
                    key: 'requestResolution',
                    value: e.target.value as string,
                  }),
                );
              }}
              fullWidth
              multiline
              rows={4}
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handlers.handleClose}>Cancelar</Button>
          <Button type='submit'>Guardar</Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}
